export const MEAL_PLAN_KETO_LIST = [
  {
    title: 'Personalized 28-day keto meal plan'
  },
  {
    title: 'Low-carb snack list'
  },
  {
    title: 'Simple affordable ingredients'
  },
  {
    title: '24/7 nutritionist support'
  },
  {
    title: 'Tailored to your weightloss goals'
  },
  {
    title: 'Share recipes with family & friends!'
  },
  {
    title: 'Available on all devices'
  },
  {
    title: '100% money-back garantee'
  }
]

export const MEAL_PLAN_REVIEWS = [
  {
    gender: 'female',
    user_name: 'Adriana',
    subtitle: '5 months on keto',
    weight_loss_kg: 33.1,
    review_image: '/images/onboarding/meal_plan/offer/adriana-review.png',
    review_text:
      'After having 3 kids my extra weight really started to affect me. In December 2020 I started looking into keto. My proudest achievement is not only my 56lbs fat loss, but being a happy, more energetic mother to my 3 beautiful babies!',
    review_collapsed: true,
    created_at: new Date()
  },
  {
    gender: 'male',
    user_name: 'Robert',
    subtitle: '8 months on keto',
    weight_loss_kg: 23.1,
    review_image: '/images/onboarding/meal_plan/offer/robert-review.png',
    review_text:
      "After having tried several programs for weight loss in the past two years I have found this app. Eight months in now, with no gym time, I'm down 51 pounds from my starting weight. This keto meal plan is the cornerstone of my success.",
    review_collapsed: true,
    created_at: new Date()
  },
  {
    gender: 'female',
    user_name: 'Renee',
    subtitle: '6 months on keto',
    weight_loss_kg: 9.5,
    review_image: '/images/onboarding/meal_plan/offer/renee-review.png',
    review_text:
      "I started my keto journey in October 2020 at 201! By now I am down 72 pounds and weigh 129. Keto has been the easiest way to lose weight for me. I am now 55 years old, and the healthiest I've ever been. Game changer!",
    review_collapsed: true,
    created_at: new Date()
  }
]

export const MEAL_PLAN_COMMON_QUESTIONS = [
  {
    title: 'Why choose our Meal Plan?',
    content:
      'Because it actually works! Together, our users have lost over 90 tons of unwanted weight. Eliminate the guesswork with ur easy recipes and easy to follow instructions. You can keep spending money on overpriced teas, detoxes, and other "cures" that have failed you before, or you can go low-carb with the help of our meal plan and start seeing that number on the scale go down! '
  },
  {
    title: 'How fast will I start losing weight?',
    content: `You can expect to lose some water weight at the beginning so it's not unusual to experience a weight loss rate of 2-3 pounds (1-1.5 kg) in the first 7 to 21 days. Once your body enters ketosis and starts burning fat instead of carbs, your weight will decrease by roughly two pounds (0.5-1 kg) per week. Staying consistent, tracking your macros, and being patient is key to seeing great long-term results with the ketogenic diet plan. As soon as your body adjusts to the changes you're making, results will follow.`
  },
  {
    title: 'How does keto work?',
    content: `A low-carb diet puts you in a metabolic state called ketosis, where your body is essentially carbohydrate deprived. Carbs are our main fuel, which the body breaks down into a simple sugar called glucose. With the ketogenic diet however, you're restricting carbs so strictly that your body has to seek an alternative source of energy – dietary fat. Your body then starts to convert fat stores into energy, releasing ketones in the process. In order to get and stay in ketosis, you do have to maintain a very low carb intake. This is why tracking your macros on the ketogenic diet is so essential.`
  }
]

export const RECIPE_ICON_MAP = {
  breakfast: {
    type: 'Breakfast'
  },
  lunch: {
    type: 'Lunch'
  },
  dinner: {
    type: 'Dinner'
  },
  snack: {
    type: 'Snack'
  }
}

export const MEALPLAN_PROCESS_TIP = [
  {
    content: `Take a before photo! There's nothing more rewarding than to see your progress!`
  },
  {
    content: `The time it takes to enter ketosis varies from person to person. On average, it takes 2–4 days if you stick to 20–50 grams of carbs per day. However, for some people it may take a few weeks, so be patient!`
  },
  {
    content: `Did you know?\nKetosis reduces your hunger cravings!`
  },
  {
    content: `In the first few days of keto you might experience certain side-effects like dehydration, fatigue, headaches, also known as keto flu.`
  },
  {
    content: `Stay Hydrated! 💧 Aim to drink at least half your body weight in ounces of water daily.`
  },
  {
    content: `Did you know having a diet buddy improves your results? Tell your friends you're doing keto and tag @keto_diet_app on Instagram!`
  },
  {
    content: `Congratulations, it's been a week since you started keto diet! Well done! 💥`,
    withConfetti: true
  },
  {
    content: `Get rid of foods you need to avoid. It’s much easier to stick to your goals if you’re not constantly fighting temptation!`
  },
  {
    content: `Bulletproof coffee is made by mixing coconut oil and butter into your coffee, this drink will help keep hunger at bay! ☕️`
  },
  {
    content: `Intermittent fasting may help your body reach ketosis quicker and break through the weight-loss plateau! Try fasting for 16 hours by skipping breakfast or dinner!`
  },
  {
    content: `Having a healthy grocery list helps you stock your kitchen with the right ingredients you need for keto diet. Make a low-carb grocery list and go shopping!`
  },
  {
    content: `Would you like to share your dieting experience with like-minded people, explore new recipes and share keto lifehacks? Then join our supportive <a href="https://www.facebook.com/groups/ketodietapp" target="_blank" rel="nofollow noopener">Facebook group.<a/>`
  },
  {
    content: `Tip of the day: Electrolyte supplements containing sodium, potassium and magnesium will help prevent unpleasant symptoms like headache, muscle cramps and fatigue.`
  },
  {
    content: `Two weeks low-carb! We're so proud of you 🥳  Write a letter to your future healthy self!`,
    withConfetti: true
  },
  {
    content: `Halfway there!`
  },
  {
    content: `Did you know that coconut oil 🥥 contains MCT fats, which help you get into ketosis faster?`
  },
  {
    content: `Share your keto journey and treat your family to a keto meal!`
  },
  {
    content: `Get Enough Sleep! 🛌 Maintaining a proper sleep schedule helps you stay in ketosis. Aim to get at least 7-9 hours.`
  },
  {
    content: `Stay motivated! Results happen overtime ⏰, not overnight!`
  },
  {
    content: `Since fat makes up the majority of the calories on the ketogenic diet, it's important to choose high-quality sources. Good fats include olive oil, avocado oil, coconut oil, butter, lard and tallow.`
  },
  {
    content: `What you put on your fork 🍴 is truly more powerful than anything you will find in a prescription bottle 💊.`,
    withConfetti: true
  },
  {
    content: `Did you know that stevia, erythritol and monk fruit have 0 net carbs per serving and are just as sweet as sugar.`
  },
  {
    content: `Increase your physical activity levels. Exercise helps your body to produce ketones, making you reach ketosis faster.`
  },
  {
    content: `Tip of the day: reduce portion sizes by eating off smaller plates 🍽`
  },
  {
    content: `Be smart with your cravings! Stock up on keto-friendly sweeteners and prepare keto desserts ahead of time.`
  },
  {
    content: `Make sure to limit your proteins! Be careful with eating too much as it could lead to gluconeogenesis, a process where your body turns protein into carbs. This can kick you out of ketosis!`
  },
  {
    content: `Did you know that your liver converts fat to ketone bodies which can then be used by the brain as energy? This is why keto increases cognitive function!`
  },
  {
    content: `Make an after photo. If you're happy with the progress share it on your social media. Repeat the plan for 28 more days!`,
    withConfetti: true
  }
]

export const DAY_INDEX_KEY = 'currentIndex'

export const examplesReplaceMeals = [
  {
    totalTime: '00:15',
    images: {
      high: '/images/onboarding/meal_plan_alt/recipeMeal3.jpg'
    },
    calories: 473,
    name: 'Stuffed Chicken Breasts',
    net_carbs: 5
  },
  {
    images: {
      high: '/images/onboarding/meal_plan_alt/recipeMeal1.jpg'
    },
    calories: 446,
    totalTime: '00:15',
    net_carbs: 2,
    name: 'Spiced Shrimp Bowl'
  },
  {
    totalTime: '00:15',
    images: {
      high: '/images/onboarding/meal_plan_alt/recipeMeal2.jpg'
    },
    net_carbs: 4,
    calories: 420,
    name: 'Taco Stuffed Pepper'
  },
  {
    images: {
      high: '/images/onboarding/meal_plan_alt/recipeMeal4.jpg'
    },
    name: 'Pecan-Parmesan-Parsley Crust...',
    calories: 510,
    net_carbs: 4,
    totalTime: '00:15'
  }
]

export const MEALPLAN_APP_SLIDES = [
  {
    isDefaultSlide: true,
    url: '/images/onboarding/meal_plan_alt/appSlide1.png'
  },
  {
    isDefaultSlide: true,
    url: '/images/onboarding/meal_plan_alt/appSlide2.png'
  },
  {
    isDefaultSlide: true,
    url: '/images/onboarding/meal_plan_alt/appSlide3.png'
  },
  {
    isDefaultSlide: false,
    isSpecialSlide: true
  }
]
