import {
  ALLERGIES,
  MEALPLAN_FOOD,
  MEALPLAN_MEAT,
  MEALPLAN_PREP_TIME
} from '@/enums'

export const TAGS_MAPPING = Object.freeze({
  [MEALPLAN_PREP_TIME.LESS30MIN]: ['<30 min'],
  [MEALPLAN_PREP_TIME.LESS60MIN]: ['<30 min', '>30  <60 min'],
  [MEALPLAN_PREP_TIME.MORE60MIN]: [], // ALL

  [MEALPLAN_MEAT.ALL]: [], // ALL
  [MEALPLAN_MEAT.CHICKEN]: ['Chicken'],
  [MEALPLAN_MEAT.PORK]: ['Pork'],
  [MEALPLAN_MEAT.BACON]: ['Bacon'],
  [MEALPLAN_MEAT.BEEF]: ['Beef'],
  [MEALPLAN_MEAT.FISH]: ['Fish', 'Tuna'],
  [MEALPLAN_MEAT.LAMB]: ['Lamb'],
  [MEALPLAN_MEAT.TURKEY]: ['Turkey'],
  [MEALPLAN_MEAT.VEGETARIAN]: ['Vegetarian', 'Vegan'],

  [MEALPLAN_FOOD.ALL]: [],
  [MEALPLAN_FOOD.AVOCADOS]: ['Avocado'],
  [MEALPLAN_FOOD.EGGS]: ['Eggs'],
  [MEALPLAN_FOOD.MUSHROOMS]: ['Mushrooms'],
  [MEALPLAN_FOOD.ONIONS]: ['Onion'],
  [MEALPLAN_FOOD.CHEESE]: ['Cheese'],
  [MEALPLAN_FOOD.NUTS]: [
    'Nuts',
    'Walnuts',
    'Peanuts',
    'Macadamia nuts',
    'Cashews'
  ],
  [MEALPLAN_FOOD.BUTTER]: ['Butter'],
  [MEALPLAN_FOOD.COCONUT]: [
    'Coconut',
    'Coconut flour',
    'Coconut Milk',
    'Coconut Oil',
    'Coconut cream'
  ],
  [MEALPLAN_FOOD.MILK]: ['Milk'],
  [MEALPLAN_FOOD.SEAFOOD]: ['Seafood'],
  [MEALPLAN_FOOD.OLIVES]: ['Olives'],
  [MEALPLAN_FOOD.TOFU]: ['Tofu'], // no recipes
  [MEALPLAN_FOOD.COTTAGE_CHEESE]: ['Cottage cheese'], // no recipes

  [ALLERGIES.NONE]: [],
  [ALLERGIES.LACTOSE]: ['Milk'],
  [ALLERGIES.NUTS]: ['Nuts', 'Walnuts', 'Peanuts', 'Macadamia nuts', 'Cashews'],
  [ALLERGIES.MILK_PROTEIN]: ['Milk'],
  [ALLERGIES.EGG_PROTEIN]: ['Eggs'],
  [ALLERGIES.HONEY]: ['Honey'],
  [ALLERGIES.FISH]: ['Fish', 'Tuna'],
  [ALLERGIES.CITRUS]: ['Lemon', 'Orange', 'Orange peel'],
  [ALLERGIES.NOT_ANSWER]: []
})
