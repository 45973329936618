<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TermsConditions',
  computed: {
    ...mapGetters('popups', ['is_show_terms_conditions'])
  },
  methods: {
    closeTermsConditions() {
      this.$store.dispatch('popups/onTermsConditions', false)
    }
  }
}
</script>

<template>
  <div
    class="modal has-text-left"
    :class="{
      'is-active': is_show_terms_conditions
    }"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box px-2 py-2">
        <button
          v-button-gtm="{
            title: 'Close Terms And Conditions',
            screen: $options.category
          }"
          class="delete ml-2 mt-2"
          aria-label="close"
          @click="closeTermsConditions"
        />
        <div class="box__text pt-4 px-2">
          TERMS AND CONDITIONS <br /><br />
          Last Updated: October 28, 2019 <br /><br /><br />
          1. Introduction <br />
          Fjor Nutrition, Inc. ("we", "us," "our") distributes MyKeto mobile
          application (the "App") and that content available through the App,
          our emails, and our notifications (the "Content"). The App, together
          with the Content, tools, features, functionality, and other
          information and services including, without limitation, viral,
          embeddable or application/device-based features and related
          technology, accessed via the Internet, mobile or other device are
          collectively referred to as the "Service". <br /><br /><br />
          2. Acceptance of Terms and Conditions (the "Terms") <br />
          These Terms are a legal agreement between you and us and contain
          important information regarding your legal rights, remedies and
          obligations. By accessing and using the Service, you: (i) acknowledge
          that you have read, understand, and agree to be bound by these Terms;
          (ii) agree to comply with all applicable laws, rules and regulations
          with respect to your use of the Service; and (iii) represent that you
          have the legal capacity to enter into contracts in the jurisdiction
          where you reside. Our Privacy Policy constitutes a part of these Terms
          and explains how we collect, use and protect information that we learn
          about you as a result of your interaction with us through the App. You
          can find our Privacy Policy here
          <a href="http://myketo.care/pp">http://myketo.care/pp</a>.
          <br /><br />
          If you do not accept these Terms or if you are not authorized or
          eligible to be bound by them, you may not download our App, and access
          and use our Service. Your continued use of the App and the Service
          manifests your acceptance of these Terms, as revised from time to
          time. <br /><br />
          3. Modifications <br />
          We may occasionally modify the Terms or the Privacy Policy. All
          changes will be effective upon posting. You can determine when the
          Terms or the Privacy Policy were last revised by referring to the
          "Last Updated" legend at the top of the page. You agree to be bound by
          any such changes if you continue to use the App after such changes
          have been posted. We may change, restrict access to, suspend or
          discontinue the App, or any portion or feature of the App, at any
          time. <br /><br /><br />
          4. No Medical Advice <br />
          We offer our App for your educational and entertainment purposes only,
          and in no way intend to diagnose or treat any medical condition. To
          the maximum extent permitted by applicable law, you expressly agree
          that we are not providing medical or healthcare advice via the
          Service, including any advice relating to nutrition, weight loss, or
          wellness. In particular, you understand and expressly agree that the
          recommendations that you may receive through the "Insights" section of
          our App or via the in-app chat or messaging are for informational
          purposes only, are not medical or healthcare advice, and you may rely
          on such recommendations only at your own risk. You should always seek
          advice of a licensed physician regarding any medical conditions you
          may have. The Service may not be appropriate for everyone, and you
          should consult with a qualified healthcare expert to determine whether
          the Service would be safe and effective for you. You hereby confirm
          that you are solely responsible for your state of health. You
          acknowledge that your diet and exercise activities involve risks,
          including the risk of bodily injury or death, and that you assume all
          those risks. <br /><br /><br />
          5. Eligibility to Use the App <br />
          When you download and access the App, you will be asked to create an
          account. This process includes selecting a screen name and providing
          certain information about you. When creating an account, you represent
          and warrant that: (a) all required registration information you submit
          is truthful and accurate; (b) your use of the Service does not violate
          any applicable law or regulation or these Terms; (c) you have the
          legal capacity and you agree to comply with these Terms; and (d) you
          are at least 18 years of age (or a legal age in your jurisdiction) or
          have received parental consent and supervision when using the Service
          and your parent or legal guardian also agrees to be bound by these
          Terms and agrees to be responsible for your use of the Service.
          <br /><br />
          Our App is not for persons under the age of 13. If you are under 13
          years of age, then please do not use the App. <br /><br />
          By using the Service, you agree to receive certain communications,
          such as updates about the App, reminders, or healthy tips or a
          periodic e-mail newsletter from the Company. You can opt-out of
          non-essential communications by support@myketo.care. <br /><br />
          We reserve the right to suspend or terminate your account and your use
          of the App and the Service in the event you breach these Terms.
          <br /><br />
          6. Intellectual Property <br />
          The App contains trademarks and service marks owned by us or other
          third parties. Unless specifically noted that such trademarks or
          service marks belong to a third party, we own all trademarks and
          service marks displayed in the App, whether registered or
          unregistered, including but not limited to, the name MyKeto, our logo,
          our design patterns and our other graphics, the App trade dress, and
          other indicia of origin of MyKeto services. The use of our
          intellectual property is strictly prohibited, unless we have granted
          our prior written consent. <br /><br />
          The content of the App, including text, graphics, logos, button icons,
          images, data compilations, and any other works of authorship, both
          individually and as they are compiled on the App (the "Content"), and
          software used on this App, are the property of Fjor Nutrition, Inc. or
          third parties, and are protected by copyright and other United States
          and foreign intellectual property and related laws, rules and
          regulations. The Content includes both material owned or controlled by
          Fjor Nutrition, Inc. ("MyKeto Content"), and material owned or
          controlled by third parties and licensed to Fjor Nutrition, Inc.,
          including User Content (as described below). Any use of the Content
          not expressly permitted by these Terms is a breach of these Terms and
          may violate copyright, trademark and other laws. You agree to abide by
          all copyright notices, information, or restrictions contained in or
          attached to any of the Content. <br /><br />
          7. License Grant <br />
          We hereby grant you a worldwide, non-exclusive, non-transferable,
          non-sublicensable and revocable license to install the App, solely on
          your own handheld mobile device (e.g., iPhone, Android, etc. as
          applicable) and to use the App and the Service and the Content subject
          to your eligibility and your continued compliance with these Terms and
          solely for your personal, non-commercial purposes. We are not
          responsible to ensure, and disclaim any responsibility for, your
          ability to open, use or view the Content we provide. As between you
          and us, we retain all right, title and interest in and to the Content
          (other than your own User Content), and all related intellectual
          property rights. We reserve all rights not granted in these Terms.
          <br /><br />
          8. User Content <br />
          As a user of the App ("User"), you may enter your data, post content,
          chat with our representative, publish, submit or transmit your
          comments, reviews, opinions, testimonials, and other content ("User
          Content"). We do not guarantee any confidentiality with respect to any
          User Content. You shall be solely responsible for your own User
          Content and the consequences of posting or publishing it. In
          connection with User Content, you affirm, represent and warrant that:
          <br /><br />
          (a) you own or have the necessary permissions to your User Content to
          enable use of the User Content in the manner contemplated by these
          Terms; <br /><br />
          (b) neither the User Content nor your posting, uploading, publication,
          submission or transmittal of the User Content, will infringe,
          misappropriate or violate a third party's patent, trademark, trade
          secret, copyright or other proprietary rights, or rights of publicity
          or privacy, or result in the violation of any applicable law or
          regulation; and <br /><br />
          (c) you have the written consent, release, and/or permission of each
          and every identifiable individual person in the User Content to use
          the name or likeness of such person. <br /><br />
          For clarity, you retain all of your ownership rights in your User
          Content. By submitting your User Content to the App, you hereby grant
          us a worldwide, perpetual, irrevocable, non-exclusive, royalty-free,
          sub-licenseable and transferable license to (and to allow others
          acting on our behalf to) access, view, use, reproduce, adapt, modify,
          distribute, sell, stream, broadcast, prepare derivative works of,
          publicly display, publicly perform and otherwise exploit such User
          Content in connection with the Service and our business, including
          without limitation for promoting and redistributing part or all of the
          App (and derivative works thereof) in any and all media formats and
          through any and all media channels. <br /><br />
          We neither endorse the content of your communications, postings or
          data, nor assume any responsibility for any threatening, libelous,
          obscene, harassing or offensive material contained in such materials
          or any crime facilitated by use of the App.
          <br /><br />9. User Feedback <br />We are always happy to hear from
          our Users and are grateful for your comments and suggestions how to
          improve our Service. The following policy applies to all third party
          submissions of ideas, comments, suggestions, improvements, proposals
          or materials: (a) all submissions are non-confidential and
          non-proprietary; (b) we will not be liable for any use or disclosure
          of any submission; and (c) we can use the submission for any purpose
          whatsoever, commercial or otherwise, without compensation to the
          submitting person. <br /><br />
          10. Prohibited Activities <br />
          By accessing and using the App, you agree that you will not use the
          App and the Service for any unlawful or prohibited purpose. You may
          not attempt, through any means, to gain unauthorized access to any
          part of the App or the Service, other account, computer system or
          network connected to our server. We reserve the right, in our sole and
          absolute discretion, to monitor any and all use of the App and remove
          any User Content at any time.
          <br /><br />
          Without limiting the foregoing, you will not use the App and the
          Service to: <br /><br />
          (a) post or otherwise transmit any User Content that (i) is unlawful,
          false, misleading, inaccurate, harmful, threatening, abusive,
          harassing, tortious, excessively violent, defamatory, vulgar, obscene,
          pornographic, libelous, invasive of another's privacy, hateful
          racially, ethnically or that encourages conduct that would be
          considered a criminal offense, give rise to civil liability, or is
          otherwise objectionable; (ii) depicts or advocates the use of illicit
          drugs; (iii) makes use of offensive language or images; (iv) you do
          not have a right to transmit under any law or under contractual or
          fiduciary relationships; (v) poses or creates a privacy or security
          risk to any person; (vi) infringes any intellectual property or other
          proprietary rights of any party; (vii) are "junk mail," "spam," "chain
          letters," "pyramid schemes," "contests," "sweepstakes," or any other
          form of solicitation; (viii) contains software viruses or any other
          computer code, files or programs designed to interrupt, destroy or
          limit the functionality of any computer software or hardware or
          telecommunications equipment; or (ix) in our sole judgment, is
          objectionable or which restricts or inhibits any other person from
          using or enjoying the Service, or which may expose us or other users
          to any harm or liability of any type; <br /><br />
          (b) violate any applicable local, state, national, international or
          other law or regulation, or any order of a court, including without
          limitation, rules about intellectual property rights, the Internet,
          technology, data, email or privacy; <br /><br />
          (c) access the App and the Content from territories where it is
          illegal or strictly prohibited; <br /><br />
          (d) interfere with, disrupt or damage the Service, including, without
          limitation, through the use of viruses, cancel bots, Trojan horses,
          harmful code, flood pings, denial-of-service attacks, packet or IP
          spoofing, forged routing or electronic mail address information or
          similar methods or technology; <br /><br />
          (e) use the Service to transmit, distribute, post or submit any
          information concerning any other person without their permission;
          <br /><br />
          (f) harvest or collect email addresses or other contact information of
          other Users by electronic or other means without their consent;
          <br /><br />
          (g) "stalk" or harass any other User or collect or store any
          information about any other User; <br /><br />
          (h) obtain or attempt to access or otherwise obtain any materials or
          information through any means not intentionally made available or
          provided for through the Service; <br /><br />
          (i) register for more than one user account or register for a user
          account on behalf of an individual other than yourself;
          <br /><br />
          (j) transfer or sell your account and/or username to another party;
          <br /><br />
          (k) impersonate any person or entity, or falsify or otherwise
          misrepresent yourself or your affiliation with any person or entity;
          <br /><br />
          (l) use automated scripts to access, search collect information or
          otherwise interact with the Service (including without limitation
          robots, spiders or scripts) or use any robot, spider, other automatic
          device, or manual process to extract, "screen scrape," monitor,
          "mine," or copy any static or dynamic web page on the App or the
          Content contained on any such web page for commercial use without our
          prior express written permission; <br /><br />
          (m) other than with respect to your own User Content, (i) use,
          reproduce, duplicate, copy, sell, resell or exploit the Content; (ii)
          compile a collection of Content, whether by manual methods, through
          the use of bots, crawlers, or spiders, or otherwise; or (iii)
          otherwise remove any text, copyright or other proprietary notices
          contained in the Content; <br /><br />
          (n) decipher, decompile, disassemble, reverse engineer, or otherwise
          attempt to derive any source code or underlying ideas or algorithms of
          any part of the Service, except to the extent applicable laws
          specifically prohibit such restriction;
          <br /><br />
          (o) modify, translate, or otherwise create derivative works of any
          part of the Service; or <br /><br />
          (p) advocate, encourage, or assist any third party in doing any of the
          foregoing. <br /><br />
          11. Third Parties <br />
          These Terms apply only to the App and the Service. In using the
          Service, you may be exposed to content and information, for example,
          data, text, files, information, usernames, graphics, images,
          photographs, profiles, audio, video, messages, services or links, from
          other users or third parties such as producers ("Third-Party
          Content"), either in the App or through links to third-party websites
          or mobile applications. Because we do not review, monitor, operate or
          control any such Third-Party Content, you acknowledge and agree that
          we are not responsible for the availability of such websites or mobile
          applications and do not endorse and are not responsible or liable,
          directly or indirectly, for any content, advertising, products,
          services or other materials on or available from such websites or
          mobile applications. We make no guarantees, representations or
          warranties as to, and shall have no liability for, any content
          delivered by any third party, including, without limitation, the
          accuracy or subject matter of any content, or the use of any personal
          information you provide to any such website. You acknowledge and agree
          that use of such links is entirely at your own risk. We may
          discontinue links to any other website or mobile applications at any
          time and for any reason. <br /><br />
          You acknowledge and agree that the availability of the App is
          dependent on the third party from which you received the App, e.g.,
          the Apple iTunes App Store, and/or other app stores (an "App Store" or
          collectively, "App Stores"). You agree to pay all fees charged by the
          App Stores in connection with the App. You agree to comply with, and
          your license to use the App is conditioned upon your compliance with,
          all applicable agreements, terms of use/service, and other policies of
          the App Stores. You acknowledge that the App Stores (and their
          subsidiaries) are a third-party beneficiary of these Terms and will
          have the right to enforce these Terms. <br /><br />
          12. Subscription fees and payment <br />
          Although the App is free to download, its Service is offered on a
          subscription basis for a fee. You agree to pay an App Store the
          applicable fees (and any related taxes) as they become due and you
          hereby authorize them to charge the applicable fees to the payment
          card you provide. We reserve the right to change subscription fees at
          any time. We will give you reasonable notice of any such pricing
          changes by posting the new prices on or through the App and/or by
          sending you a notification. If you do not wish to pay the new fees,
          you can cancel the applicable subscription prior to the change going
          into effect. <br /><br />
          Further, you agree that your subscription will be automatically
          renewed unless you cancel prior to the renewal term. The period of
          auto-renewal will be the same as your initial subscription period
          unless otherwise disclosed to you on the Service. We will not refund
          fees that may have accrued to your account and will not prorate fees
          for a cancelled subscription. <br /><br />
          We may offer a free trial subscription for the Service. Free trial
          provides you access to the Service for a period of time, with details
          specified when you sign up for the offer. Unless you cancel before the
          end of the free trial, or unless otherwise stated, your access to the
          Service will automatically continue and you will be billed the
          applicable fees for the Service. We may send you a reminder when your
          free trial is about to end, but we do not guarantee any such
          notifications. It is ultimately your responsibility to know when the
          free trial will end. We reserve the right, in our absolute discretion,
          to modify or terminate any free trial offer, your access to the
          Service during the free trial, or any of these terms without notice
          and with no liability. We reserve the right to limit your ability to
          take advantage of multiple free trials. <br /><br />
          The Service and your rights to use it expire at the end of the paid
          period of your subscription. If you do not pay the fees or charges
          due, we may make reasonable efforts to notify you and resolve the
          issue; however, we reserve the right to disable or terminate your
          access to the Service (and may do so without notice). <br /><br />
          Subscriptions purchased via an App Store are subject to such App
          Store's refund policies. This means we cannot grant refunds. You will
          have to contact an App Store support. <br /><br />
          13. Disclaimer of Warranties <br />
          THE APP AND THE SERVICE ARE PROVIDED ON "AS IS," "WITH ALL FAULTS" AND
          "AS AVAILABLE" BASIS AND, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT
          TO APPLICABLE LAW, FJOR NUTRITION, INC. DISCLAIMS ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
          OR NON-INFRINGEMENT, AND WARRANTIES IMPLIED FROM A COURSE OF
          PERFORMANCE OR COURSE OF DEALING. YOU EXPRESSLY AGREE THAT YOUR USE OF
          OUR APP AND THE SERVICE, INCLUDING RELIANCE ON ANY RECOMMENDATIONS AND
          INFORMATION THAT IS PROVIDED AS PART OF THE SERVICE, IS AT YOUR SOLE
          RISK. WE DO NOT WARRANT THAT THE SERVICE YOU OBTAIN THROUGH OUR APP
          WILL MEET YOUR EXPECTATIONS AND REQUIREMENTS, BE UNINTERRUPTED OR
          ERROR-FREE, WILL BE AVAILABLE FOR USE, WILL BE OF A CERTAIN QUALITY,
          THAT DEFECTS WILL BE CORRECTED, THAT THE APP IS FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS, OR THAT THE APP OR THE TECHNOLOGY THAT MAKES
          IT AVAILABLE ARE IMMUNE TO HACKER ACTIVITY, ELECTRONIC OR
          NON-ELECTRONIC TAMPERING, COMPUTER CRIME OR THEFT. WE DO NOT WARRANT
          OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE
          USE OF THE APP AND THE SERVICE IN TERMS OF THEIR CORRECTNESS,
          ACCURACY, RELIABILITY, TIMELINESS, COMPLETENESS, CURRENTNESS, OR
          OTHERWISE. WE MAKE NO GUARANTEES CONCERNING THE LEVEL OF SUCCESS YOU
          MAY EXPERIENCE, AND YOU ACCEPT THE RISK THAT RESULTS WILL DIFFER FOR
          EACH INDIVIDUAL. <br /><br />
          YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
          INTERACTIONS WITH OTHER USERS. YOU UNDERSTAND THAT WE DO NOT MAKE ANY
          ATTEMPT TO VERIFY THE INFORMATION POSTED BY USERS IN THE APP.
          <br /><br />
          Some jurisdictions do not allow the exclusion of certain warranties.
          Accordingly, some of the above exclusions may not apply to you.
          <br /><br />
          14. Limitation of Liability <br />
          IN NO EVENT SHALL FJOR NUTRITION, INC. (FOR PURPOSES OF THIS SECTION,
          "FJOR NUTRITION, INC." OR "WE" INCLUDES OUR EQUITY HOLDERS, OFFICERS,
          DIRECTORS, EMPLOYEES, CONSULTANTS, AGENTS, SUCCESSORS, REPRESENTATIVES
          AND AFFILIATES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES ARISING OUT OF OR
          IN ANY WAY CONNECTED WITH YOUR USE OF THE APP OR THE SERVICE OR WITH
          THE DELAY OR INABILITY TO USE THE APP OR THE SERVICE, WHETHER BASED ON
          CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS APPLIES, WITHOUT
          LIMITATION, TO ANY DAMAGES OR INJURY ARISING FROM ERROR, OMISSION,
          INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION OF
          INFORMATION THROUGH THE APP, BODILY INJURY OR EMOTIONAL DISTRESS,
          COMPUTER VIRUS, OR DESTRUCTION, UNAUTHORIZED ACCESS TO, ALTERATION OF,
          OR MISUSE OF ANY RECORD OR DATA. WE DO NOT ASSUME ANY LIABILITY FOR
          INACCURACIES OR MISSTATEMENTS ABOUT THE DIET, FOOD RECIPES, EXERCICES
          OR OTHER CONTENT ON THE SERVICE. THE COMPANY DISCLAIMS ANY LIABILITY
          FOR LOSS IN CONNECTION WITH THE CONTENT PROVIDED ON THE SERVICE. YOU
          ARE ENCOURAGED TO CONSULT WITH YOUR DOCTOR AND OTHER RELEVANT
          PROFESSIONALS WITH REGARD TO THE INFORMATION CONTAINED ON OR ACCESSED
          THROUGH THE SERVICE.
          <br /><br />
          IN NO EVENT WILL OUR TOTAL LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY
          DAMAGES, LOSSES, AND CAUSES OF ACTION RESULTING FROM YOUR USE OF THE
          APP OR THE SERVICE, WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT
          LIMITED TO NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EXCEED THE
          AMOUNT OF $100. <br /><br />
          Applicable law may not allow the limitation or exclusion of liability
          or incidental or consequential damages, so the above limitation or
          exclusion may not apply to you. <br /><br />
          15. Release <br />
          To the fullest extent permitted by law, you release us, our
          subsidiaries and affiliated companies, and their respective officers,
          employees, directors, contractors and agents from any and all
          responsibility, liability, claims, demands, and/or damages (actual and
          consequential) of every kind and nature, known and unknown, arising
          out of or in any way connected with the following: (i) your use of the
          App and the Service; (ii) disputes between Users, including those
          between you and other Users; (iii) User Content or Third Party
          Content; (iv) claims relating to the unauthorized access to any data
          communications or content stored under or relating to your account,
          including unauthorized use or alteration of such communications or
          your User Content.
          <br /><br />
          <br /><br />
          16. Indemnification <br />
          You agree to defend, indemnify and hold harmless Fjor Nutrition, Inc.,
          our subsidiaries and affiliated companies, and their respective
          officers, employees, directors, contractors and agents, from and
          against any and all claims, causes of actions, suits or proceedings,
          as well as any and all losses, liabilities, damages, costs and
          expenses (including attorneys' fees) and all amounts paid in
          settlement arising out of, connected with, or accruing from (a) your
          access to and use of the App, the Service and the Content; (b) your
          violation of these Terms, (c) your violation of any applicable law;
          (d) your User Content, or (e) your interaction with any other User. We
          may assume the exclusive defense and control of any matter for which
          you have agreed to indemnify us and you agree to assist and cooperate
          with us in the defense or settlement of any such matters.
          <br /><br />17. English Language <br />In the event of a conflict
          between these Terms and a foreign language version of these Terms, the
          English language version will govern. All disputes, claims and causes
          of action (and related proceedings) will be communicated in English.
          <br /><br />
          18. International Use <br />
          We do not represent or warrant that the App, or any part thereof, is
          appropriate or available for use in any particular jurisdiction. Those
          who choose to access and download the App do so on their own
          initiative and at their own risk, and are responsible for complying
          with all local laws, rules and regulations, including laws regulating
          the export of data. We may limit the availability of the App, in whole
          or in part, to any person, geographic area or jurisdiction that we
          choose, at any time and in our sole discretion. Accessing and
          downloading the App is prohibited from territories where the App's
          Content is illegal.
          <br /><br />
          You represent and warrant that you are in compliance with all laws,
          restrictions and regulations administered by the Office of Foreign
          Assets Control ("OFAC") or any other Governmental Entity imposing
          economic sanctions and trade embargoes ("Economic Sanctions Laws")
          against designated countries ("Embargoed Countries"), entities and
          persons (collectively, "Embargoed Targets"). You represent and warrant
          that you are not an Embargoed Target or otherwise subject to any
          Economic Sanctions Law. You agree to comply with all Economic
          Sanctions Laws. Without limiting the generality of the foregoing, you
          agree not to (a) directly or indirectly export, re-export, transship
          or otherwise deliver any products, services, technology or information
          of any portion thereof to an Embargoed Target or (b) broker or
          otherwise facilitate any transaction in violation of any Economic
          Sanctions Laws. <br /><br />
          19. Right to Discontinue Services; Termination <br />
          We may, in our sole discretion and without liability to you or to any
          third party, with or without cause, with or without notice, suspend or
          discontinue, temporarily or permanently, the App and the Service (or
          any part thereof) and, if you breach these Terms, suspend or terminate
          your account. Termination may result in the forfeiture and destruction
          of all information associated with your account. We may retain your
          account information after you terminate in accordance with our
          regulatory, accounting, and legal compliance procedures. YOU AGREE
          THAT WE WILL NOT BE LIABLE TO YOU OR ANY OTHER PARTY FOR TERMINATION
          OF YOUR ACCESS TO THE APP OR THE SERVICE IF YOU BREACH THESE TERMS.
          All provisions of these Terms that by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability. <br /><br />
          20. Copyright Notice Policy <br /><br />
          A. Reporting Claims of Copyright Infringement <br />
          We take claims of copyright infringement seriously. We will respond to
          notices of alleged copyright infringement that comply with applicable
          law. If you believe any materials accessible on or from the App
          infringe your copyright, you may request removal of those materials
          (or access to them) from the App by submitting a written notification
          to our Copyright Agent at the address or email listed in Part C below.
          Please note that if you knowingly materially misrepresent that certain
          material or activity on the App is infringing your copyright, you may
          be held liable for damages (including costs and attorneys' fees) under
          Section 512(f) of the DMCA. <br /><br />
          In accordance with the Online Copyright Infringement Liability
          Limitation Act of the Digital Millennium Copyright Act of 1998 (<em
            >17 U.S.C. § 512)</em
          >
          ("DMCA"), the written notice (the "DMCA Notice") must include
          substantially the following: <br /><br />
          <ol>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site.
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material.
            </li>
            <li>
              Information reasonably sufficient to permit us to contact the
              complaining party, such as an address, telephone number, and, if
              available, an email address at which the complaining party may be
              contacted.
            </li>
            <li>
              A statement that the complaining party has a good faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law.
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that the complaining party is
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed.
            </li>
          </ol>
          <br />
          If you fail to comply with all of the requirements of Section
          512(c)(3) of the DMCA, your DMCA Notice may not be effective.
          <br /><br />
          B. Counter-Notice by Accused Member <br /><br />
          If you are a User and we have taken down your User Content due to
          suspicion of copyright infringement, you may dispute the alleged
          infringement by sending a written communication (a "Counter-Notice")
          to our Copyright Agent, at the email listed in Part C below. The
          Counter-Notice must include the following information: <br />
          <ol>
            <li>A physical or electronic signature of the accused party.</li>
            <li>
              Identification of the material that has been removed or to which
              access has been disabled and the location at which the material
              appeared before it was removed or access to it was disabled.
            </li>
            <li>
              A statement made under penalty of perjury that the User has a good
              faith belief that the material was removed or disabled as a result
              of mistake or misidentification of the material to be removed or
              disabled.
            </li>
            <li>
              The User's name, address, and telephone number, and a statement
              that the User consents to the jurisdiction of Federal District
              Court for the judicial district in which such address is located,
              or if the User's address is outside of the United States, for any
              judicial district in which the service provider may be found, and
              that the User will accept service of process from the person who
              provided notification of copyright infringement or an agent of
              such person.
              <br /><br />
            </li>
          </ol>
          Remember that even though we process DMCA Notice and the
          Counter-Notice, we do not adjudicate these disputes. The DMCA allows
          us to restore the removed content if the party filing the original
          DMCA Notice does not file a court action against you within ten
          business days of receiving the copy of your Counter-Notice.
          <br /><br /><br />
          Please be aware that if you knowingly materially misrepresent that
          material or activity was removed or disabled by mistake or
          misidentification, you may be held liable for damages (including costs
          and attorneys' fees) under Section 512(f) of the DMCA.
          <br /><br /><br />
          C. Agent for Notices <br /><br />
          Please send all notices required by this policy to our Copyright Agent
          at: <br />
          Copyright Agent <br />
          c/o Fjor Nutrition, Inc. <br />
          E-mail address: copyright@myketo.care <br /><br /><br />
          D. Information for the Users <br />
          If your User Content is subject to the DMCA Notice, you will receive a
          notification from us explaining the status of your User Content and
          the next steps. Any material that is subject to the DMCA Notice will
          be removed until the dispute is resolved or the DMCA process is
          complete. <br /><br />
          E. Termination of Repeat Infringers <br /><br />
          In appropriate circumstances, we will terminate the accounts of Users
          who are repeat copyright infringers. <br /><br />
          F. Revision of Policy <br /><br />
          We may revise this policy at any time, including by posting a new
          version on our website. <br /><br />21. No Legal Advice <br />Nothing
          contained in these Terms or the Service constitutes legal advice. If
          you have any questions regarding your legal rights and legal
          obligations, you should consult with your attorney. <br /><br />
          22. Statute of Limitations <br />
          Regardless of any statute or law to the contrary, any claim or cause
          of action arising out of or related to the App, the Content, the
          Service, these Terms and/or Privacy Policy must be filed within one
          (1) year after such claim or cause of action arose or be forever
          barred. <br /><br /><br />
          23. BINDING ARBITRATION AGREEMENT AND CLASS WAIVER DISCLOSURE
          <br />
          Agreement to Arbitration; Class Waiver: You and we agree, except as
          provided below regarding small claims court proceedings, any dispute,
          claim or controversy arising out of or relating in any way to the App,
          the Service and the Content, including, but not limited to, our
          Privacy Policy and/or our privacy practices generally, these Terms,
          and this Arbitration Agreement, shall be determined by binding
          arbitration. Arbitration is more informal than a lawsuit in court.
          Arbitration uses a neutral arbitrator instead of a judge or jury,
          allows for more limited discovery than in court, and is subject to
          very limited review by courts. YOU AGREE THAT, BY AGREEING TO THESE
          TERMS, THE U.S. FEDERAL ARBITRATION ACT GOVERNS THE INTERPRETATION AND
          ENFORCEMENT OF THESE TERMS, AND THAT YOU AND WE ARE EACH WAIVING THE
          RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. This
          arbitration provision shall survive termination of these Terms.
          <br /><br /><br />
          Pre-Filing Mediation. In the event of a dispute, prior to initiating
          arbitration, the party asserting the claim must first send to the
          other, by overnight courier, a written Notice of Claim ("Notice"). If
          you are the claimant, the Notice to Us must be addressed to: [ADDRESS]
          or by emailing hello@myketo.care. If we are the claimant, the Notice
          must be sent to the address we have on file for you in your Account.
          The Notice must (a) describe the nature and basis of the claim or
          dispute; and (b) set forth the specific relief sought. Following
          receipt of the Notice, each party agrees to negotiate with the other
          in good faith about the claim. If the claim is not resolved to the
          satisfaction of the claimant within sixty (60) days after Notice is
          provided, the claimant may pursue the claim in arbitration or file a
          claim in small claims court, as appropriate. <br /><br />
          Additional Arbitration Provisions: <br /><br />
          (a) Settlement Offers: During the arbitration, the amount of any
          settlement offer made by you or Us shall not be disclosed to the
          arbitrator. <br /><br />
          (b) Initiating A Claim: The form required to initiate an arbitration
          can be printed or downloaded from
          <a href="http://www.adr.org">www.adr.org</a>. Alternatively, you may
          contact the arbitration administrator by calling 1-800-778-7879 or
          writing to the American Arbitration Association, 120 Broadway, Floor
          21, New York, NY 10271. <br /><br />
          (c) Fees for Initiating Arbitration: If you are required to pay a
          filing fee to initiate arbitration, after We receive notice of the
          initiation of arbitration, We will promptly reimburse you for your
          payment of the filing fee at the address provided in the Notice,
          unless your claim is for greater than US$10,000.
          <br /><br /><br />
          (d) Applicable Rules; Administrator: The arbitration will be governed
          by the Commercial Arbitration Rules and the Supplementary Procedures
          for Consumer Related Disputes (collectively, "AAA Rules") of the
          American Arbitration Association (the "AAA"), as modified by these
          Terms, and will be administered by the AAA. The AAA Rules and Forms
          are available online at
          <a href="http://www.adr.org">www.adr.org</a>, by calling the AAA at
          1-800-778-7879 or writing to the American Arbitration Association, 120
          Broadway, Floor 21, New York, NY 10271. The arbitrator is bound by the
          terms of these Terms. All issues are for the arbitrator to decide,
          including issues relating to the scope and enforceability of this
          Arbitration Agreement. <br /><br />
          (e) Location of Hearing: Unless you and We agree otherwise, any
          arbitration hearings will take place in New York, New York. If your
          claim is for US$10,000 or less, we agree you may choose whether the
          arbitration will be conducted solely on the basis of documents
          submitted to the arbitrator, through a telephonic hearing, or by an
          in-person hearing as established by the AAA Rules. If your claim
          exceeds US$10,000, the right to a hearing will be determined by the
          AAA Rules. Regardless of the manner in which the arbitration is
          conducted, the arbitrator shall issue a written decision sufficient to
          explain the essential findings and conclusions on which the award is
          based. <br /><br />
          (f) Class Waiver: YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST
          THE OTHER ONLY IN AN INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
          Further, unless both you and We agree otherwise, the arbitrator may
          not consolidate the claims of more than one party, and may not
          otherwise preside over any form of a representative or class
          proceeding. If this specific provision is found to be unenforceable as
          applied to any claim asserted by any individual, then the entirety of
          the arbitration provision set forth herein shall be null and void.
          <br /><br />
          (g) Injunctive Relief Limitation: The arbitrator may award declaratory
          or injunctive relief only in favor of the individual party seeking
          relief and only to the extent necessary to provide the relief
          warranted by that party's individual claim.
          <br /><br />
          (h) Small Claims Procedure Alternative: Notwithstanding the foregoing
          binding arbitration procedure, either party may bring an individual
          action in small claims court (provided the claim otherwise qualifies
          for such a program) as an alternative to proceeding with arbitration.
          <br /><br />
          Opt-Out Provision. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION
          PROVISION WITHIN THIRTY (30) DAYS FROM THE DATE THAT YOU FIRST USE, OR
          ATTEMPT TO USE, THE SERVICE BY WRITING TO HELLO@MYKETO.CARE OR TO THE
          ARBITRATION NOTICE ADDRESS. FOR YOUR OPT-OUT TO BE EFFECTIVE, YOU MUST
          SUBMIT A SIGNED WRITTEN NOTICE OPTING OUT AND CONTAINING ENOUGH
          DETAILS ABOUT YOU FOR US TO BE ABLE TO IDENTIFY YOU WITHIN THIRTY (30)
          DAYS. IF MORE THAN THIRTY (30) DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE
          TO OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH
          BINDING ARBITRATION AS SET FORTH IN THESE TERMS. <br /><br />
          24. Miscellaneous <br />
          (a) Reservation of Rights. We reserve all rights not expressly granted
          by these Terms. <br /><br />
          (b) Material Terms. You acknowledge and agree that the binding
          arbitration agreement and the class action waiver, as well as warranty
          disclaimers and liability and remedy limitations in these Terms are
          material terms of these Terms and that they have been taken into
          account in the decision by us to provide the App.
          <br /><br />
          (c) Severability. If any term or provision of these Terms is found by
          a court of competent jurisdiction to be invalid, illegal, or otherwise
          unenforceable, that term or provision will be deemed modified to the
          extent necessary to render such term or provision enforceable, and the
          terms and conditions hereunder will be construed and enforced
          accordingly, preserving to the fullest permissible extent the intent
          of these Terms. <br /><br />
          (d) Assignment; Waiver. You may not transfer, assign or delegate any
          of your rights, obligations or privileges hereunder; we may do so at
          any time. Any assignment of the foregoing other than as provided for
          in this section shall be null and void. We may transfer or assign any
          and all of its rights and obligations under these Terms to any other
          person, by any way, including by novation. No waiver of any provision
          or any right granted hereunder will be effective unless set forth in a
          written instrument signed by the waiving party. No waiver by either
          party of any breach or default hereunder shall be deemed a waiver of
          any subsequent breach or default. <br /><br />
          (e) Complete Agreement. These Terms, including any references
          mentioned and incorporated herein, together with such changes as may
          be subsequently made by we, constitute the complete agreement between
          you and us, our subsidiaries, affiliated companies, licensors, and
          those third parties assisting in the operation of the App with respect
          to the subject matter of these Terms and supersedes all prior
          agreements and understandings, written or oral. these Terms may not be
          amended by the Users. These Terms supersede any previous quotations,
          correspondence, or other communications, written or oral, between you
          and us. In no event shall we be liable for any failure to comply with
          these Terms to the extent that such failure arises from factors
          outside of our reasonable control. <br /><br />
          (f) Electronic Communications. All information communicated as part of
          the Service is considered an electronic communication. When you
          communicate with us through the App or via other forms of electronic
          media, such as e-mail, you are communicating with us electronically.
          You agree that we may communicate electronically with you and that
          such communications, as well as notices, disclosures, agreements, and
          other communications that we provide to you electronically, are
          equivalent to communications in writing and shall have the same force
          and effect as if they were in writing and signed by the party sending
          the communication. You further acknowledge and agree that by clicking
          on a button labeled "SUBMIT", "CONTINUE", "REGISTER", "I AGREE" or
          similar links or buttons, you are submitting a legally binding
          electronic signature and are entering into a legally binding contract.
          You acknowledge that your electronic submissions constitute your
          agreement and intent to be bound by these Terms. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS
          AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES AND RECORDS OF
          TRANSACTIONS INITIATED OR COMPLETED THROUGH THE SERVICE.
          <br /><br />
          25. Questions about Our Terms of Use <br /><br />
          If you have any questions or concerns regarding these Terms, you may
          contact us by e-mail at support@myketo.care. <br /><br />
          I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED
          ABOVE. <br /><br /><br />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  max-height: 100vh;
  margin: 0;
}
.box {
  &__text {
    max-height: calc(100vh - 5rem);
    overflow: auto;
  }
}

ol,
ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
ul {
  list-style-type: disc;
}
</style>
