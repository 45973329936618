<script>
import { mapState } from 'vuex'
import Intro from '@/components/Intro'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import TermsConditions from '@/components/TermsConditions'
import MoneyBackPolicy from '@/components/MoneyBackPolicy'

export default {
  name: 'DefaultLayout',
  components: {
    Intro,
    PrivacyPolicy,
    TermsConditions,
    MoneyBackPolicy
  },
  computed: {
    ...mapState('experiment', ['designs']),
    ...mapState('onboarding', ['start_intro']),
    greyBackground() {
      return this.$router.currentRoute.path === '/offer/trial'
    }
  }
}
</script>

<template>
  <main
    :class="`${designs.join(' ')}${
      greyBackground ? ' has-background-light' : ''
    }`"
  >
    <nuxt class="main" />
    <client-only>
      <Intro v-show="start_intro" />
      <PrivacyPolicy />
      <TermsConditions />
      <MoneyBackPolicy />
    </client-only>
  </main>
</template>

<style lang="scss">
@import 'assets/scss/main';
@import 'assets/scss/onboarding/v1';

.main {
  min-height: 100vh;
  box-sizing: border-box;

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}
</style>
