<script>
import PrivacyPolicy from '@/components/PrivacyPolicy'
import TermsConditions from '@/components/TermsConditions'
import MoneyBackPolicy from '@/components/MoneyBackPolicy'

export default {
  name: 'MealplanLayout',
  components: {
    PrivacyPolicy,
    TermsConditions,
    MoneyBackPolicy
  }
}
</script>

<template>
  <div class="layout-container">
    <main class="container">
      <nuxt class="main" />
    </main>
    <PrivacyPolicy />
    <TermsConditions />
    <MoneyBackPolicy />
  </div>
</template>

<style lang="scss">
@import 'assets/scss/main';
@import 'assets/scss/onboarding/v1';

.main {
  min-height: 100vh;
  box-sizing: border-box;

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}

.layout-container {
  background-color: #f8f6f4;
}
</style>
