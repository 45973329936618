import Vue from 'vue'
import { format } from 'date-fns'

function filterRound(value, precision = 2, mathNameFunc = 'round') {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  if (typeof value !== 'number' || isNaN(value)) {
    return value
  }

  return Math[mathNameFunc](value * 10 ** precision) / 10 ** precision
}

Vue.filter('round', (value, precision = 2) =>
  filterRound(value, precision, 'round')
)

Vue.filter('floor', (value, precision = 2) =>
  filterRound(value, precision, 'floor')
)

Vue.filter('ceil', (value, precision = 2) =>
  filterRound(value, precision, 'ceil')
)

Vue.filter('date_format', (value, dateTimeFormat = 'yyyy-MM-dd hh:mm') =>
  format(value, dateTimeFormat)
)

Vue.filter('time_transform', (value, separator = ':') => {
  if (!value) {
    return ''
  }

  const [hour, min] = value.split(separator)
  return hour * 60 + Number(min)
})

Vue.filter('capitalize', value => {
  if (!value) {
    return ''
  }

  const transformedValue = value.toString()
  return (
    transformedValue.charAt(0).toUpperCase() +
    transformedValue.slice(1).toLowerCase()
  )
})
