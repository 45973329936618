<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MoneyBackPolicy',
  computed: {
    ...mapGetters('popups', ['is_show_money_back_policy'])
  },
  methods: {
    closeMoneyBackPolicy() {
      this.$store.dispatch('popups/onMoneyBackPolicy', false)
    }
  }
}
</script>

<template>
  <div
    class="modal"
    :class="{
      'is-active': is_show_money_back_policy
    }"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box px-2 py-2">
        <button
          v-button-gtm="{
            title: 'Close Money-Back Policy',
            screen: $options.category
          }"
          class="delete ml-2 mt-2"
          aria-label="close"
          @click="closeMoneyBackPolicy"
        />
        <div class="box__text pt-4 px-2">
          <div class="title has-text-centered is-size-4">
            Keto Diet App Money-back Policy
          </div>
          I. MONEY-BACK POLICY <br /><br />
          In addition to refund rights available under applicable laws, if you
          made a purchase directly on our website and the money-back option was
          presented to you during the checkout and if you did not achieve
          visible results with our personal plan, you are eligible to receive a
          refund provided that all of the following conditions are met:
          <br /><br />
          <ul>
            <li>
              you contacted us within <strong>30 days</strong> after the
              purchase and
            </li>
            <li>
              you have followed the program for at least
              <strong>14 consecutive days</strong> within the first 30 days
              after the purchase.
            </li>
          </ul>
          We will review your application and notify you by email whether your
          application is approved. If the application is approved, your refund
          will be processed, and a credit will be automatically applied to your
          credit card or original method of payment, within 14 days.
          <br /><br />
          Customers who subscribed to our program through an App Provider will
          need to reach out to Apple directly with any refund requests. Apple is
          typically able to honor all refund requests within 30 days of the
          purchase date (at their discretion). <br /><br />
          IMPORTANT STATEMENT <br /><br />
          Please note that only fulfilment of all the above requirements allows
          you to receive a full Voluntary Refund under "Money-back guarantee".
          For the sake of clarity, this "Money-back guarantee" does not apply to
          the following cases: <br /><br />
          <ol>
            <li>
              Personal reasons (you don't like the product, it did not meet your
              expectations etc.);
            </li>
            <li>
              Financial reasons (you did not expect that you will be charged,
              that the trial will be converted into subscription, that the
              subscription will automatically renew, or that the services are
              paid etc.).
            </li>
          </ol>
          This Money-back Policy was posted on 13 December 2021. It is effective
          upon acceptance for new users and from 13 December 2021 for all other
          users. The Money-Back Policy is incorporated into and is subject to
          the Terms of Service unless otherwise provided for therein.
          <br /><br />
          CANCELATION POLICY <br /><br />
          We value our customers and you are welcome to cancel your subscription
          any time by request. Please contact us at
          <strong>support@myketo.care</strong> or within the app. <br /><br />
          Please, be aware that deleting the app or your account does not imply
          that the subscription is canceled. <br /><br />
          DELIVERY POLICY <br /><br />
          After the successful purchase, you will get access to your personal
          plan immediately tailored for you in our iOS app. Additionally, you
          will get an email with the link to the plan. You will just need to
          download the app and start. <br /><br />
          If after the payment you somehow did not receive the download link,
          please contact us at
          <strong>support@myketo.care.<br /><br /><br /></strong> II. GENERAL
          REFUND RULES <br /><br />
          Generally, if you do not meet the conditions set out above, the fees
          you have paid are non-refundable and/or non-exchangeable, unless
          otherwise is stated herein or is required by applicable law.
          <br /><br />
          Note for residents of certain US states: If you reside in California
          or Connecticut and cancel the purchase at any time prior to midnight
          of the third business day after the date of such purchase, we will
          return the payment you have made. <br /><br />
          Note for the EU residents: If you are an EU resident, you have the
          right to withdraw from the agreement for purchase of digital content
          without charge and without giving any reason within fourteen (14) days
          from the date of such agreement conclusion. The withdrawal right does
          not apply if the performance of the agreement has begun with your
          prior express consent and your acknowledgment that you thereby lose
          your right of withdrawal. You hereby expressly consent to the
          immediate performance of the agreement and acknowledge that you will
          lose your right of withdrawal from the agreement once our servers
          validate your purchase and the applicable purchase is successfully
          delivered to you. Therefore, you will not be eligible for a refund,
          unless the digital content is defective.<br />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  max-height: 100vh;
  margin: 0;
}
.box {
  &__text {
    max-height: calc(100vh - 5rem);
    overflow: auto;
  }
}

ol,
ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
ul {
  list-style-type: disc;
}
</style>
