import {
  MEALPLAN_MEAT,
  MEALPLAN_PREP_TIME,
  MEALPLAN_FOOD,
  ALLERGIES,
  MEALPLAN_FOOD_EMOJI,
  MEALPLAN_MEAT_EMOJI
} from '@/enums'
import {
  allergyCheckedTemplate,
  foodCheckedTemplate
} from '@/helpers/screen-utils'

export const screens = {
  keto_goal: {
    title: 'Find out what you can achieve with Keto Diet',
    subtitle: 'I want to:'
  },
  keto_familiarity: {
    title: 'How familiar are you with the Keto diet?',
    options: {
      beginner: { icon: '🤔', title: 'Beginner' },
      medium: { icon: '😎', title: "I've heard a thing or two" },
      expert: { icon: '🤓', title: 'Expert' }
    }
  },
  weight_loss_preview: {
    prime_color: '#07b880',
    title: 'Keto Diet App creates long&#8209;term results',
    cta: 'Continue'
  },
  success_story: {
    hide_progress: true,
    title: 'Keto Diet App makes weight loss <em>easy</em>',
    cta: 'Continue'
  },
  gender: {
    title: 'What’s your gender?',
    subtitle:
      'Your privacy is our priority. We will never share your personal data with anyone',
    options: {
      female: { icon: '👩', title: 'Female' },
      male: { icon: '👱‍♂️', title: 'Male' }
    }
  },
  body_shape: {
    title: 'What’s your body type?',
    options: {
      male: [
        'Trapezoid',
        'Rounded',
        'Triangle',
        'Rectangle',
        'Inverted Triangle'
      ],
      female: [
        'Hourglass',
        'Rounded',
        'Triangle',
        'Rectangle',
        'Inverted Triangle'
      ]
    },
    cta: 'Continue'
  },
  age: {
    title: 'What’s your age?',
    cta: 'Continue'
  },
  weight: {
    title: 'What’s your current weight?',
    cta: 'Continue'
  },
  weight_goal: {
    title: 'What’s your goal weight?',
    cta: 'Continue'
  },
  height: {
    title: 'What’s your height?',
    cta: 'Continue'
  },
  activity: {
    title: "What's your typical week like?",
    options: {
      notActive: {
        id: 0,
        level: 1.2,
        icon: '🛋',
        title: 'Not active',
        text: 'Mostly sedentary'
      },
      lightlyActive: {
        id: 1,
        level: 1.375,
        icon: '👩‍💻',
        title: 'Lightly active',
        text: 'Activities of daily living only'
      },
      moderatelyActive: {
        id: 2,
        level: 1.465,
        icon: '🚶‍♀️️',
        title: 'Moderately active',
        text: 'Walking 1.5 to 3 miles daily'
      },
      active: {
        id: 3,
        level: 1.55,
        icon: '🚴‍♀️',
        title: 'Active',
        text: 'Walking or exercising daily'
      }
    }
  },
  last_time_ideal_weight: {
    title: 'When was the last time you were at your ideal weight?',
    options: {
      lessThanYear: {
        title: 'Less than a year ago'
      },
      oneToTwoYears: {
        title: '1 to 2 years ago'
      },
      moreThanTwoYears: {
        title: 'More than 3 years ago'
      }
    }
  },
  calculating_plan_v5: {
    title_mid: 'Preparing your\ncustomized program',
    title_low: 'Preparing...',
    subtitle: 'Trusted by over 185,224 customers',
    hide_progress: true,
    items: [
      {
        title: 'Demographic Profile',
        title_mid: 'Demographic Profile',
        title_low: 'Demographic Profile',
        duration: 3.0
      },
      {
        title_low: 'Health and Medical Conditions',
        title: 'Health and Medical Conditions',
        title_mid: 'Health and Medical Conditions',
        duration: 5.0,
        popup: {
          title: 'Do you have any significant back issues?',
          relative_time: 0.4,
          options: [
            {
              title: 'Yes'
            },
            {
              title: 'No'
            }
          ]
        }
      },
      {
        title: 'Calculating Nutrition Needs',
        title_low: 'Calculating nutrition needs',
        title_mid: 'Calculating nutrition needs',
        duration: 4.0,
        popup: {
          title: 'Do you eat at roughly the same time for each meal everyday?',
          relative_time: 0.2,
          options: [
            {
              title: 'Yes'
            },
            {
              title: 'No'
            }
          ]
        }
      },
      {
        title: 'Activity and Habits',
        title_low: 'Activity and Habits',
        title_mid: 'Activity and Habits',
        duration: 5.0,
        popup: {
          title:
            'At work, are you typically on your feet or sitting at a desk for most of the day?',
          relative_time: 0.8,
          options: [
            {
              title: 'Active'
            },
            {
              title: 'Seated'
            }
          ]
        }
      }
    ]
  },
  authors: {
    hide_progress: true,
    title: 'Our program developed by world-class nutrition experts',
    cta: 'Continue'
  },
  what_is_keto: {
    hide_progress: true,
    title: 'What is Keto diet?',
    subtitle:
      'It is a low-carb, high-fat diet that puts your body into a metabolic state called ketosis. It has various health benefits:',
    cta: 'Continue'
  },
  risks_list: {
    title: 'Are you at risk of any of the following?',
    cta: 'Continue',
    options: {
      none: {
        idx: 0,
        title: 'None'
      },
      diabetes: {
        idx: 1,
        title: 'Diabetes'
      },
      highBloodPressure: {
        idx: 2,
        title: 'High blood pressure'
      },
      highCholesterol: {
        idx: 3,
        title: 'High cholesterol'
      },
      anxietyOrDepression: {
        idx: 4,
        title: 'Anxiety or depression'
      },
      bulimia: {
        idx: 5,
        title: 'Bulimia'
      }
    }
  },
  calculating_plan: {
    hide_progress: true,
    title: 'Calculating your personal plan…',
    plan: [
      {
        text: 'Analyzing your profile details'
      },
      { text: 'Searching for a suitable program' },
      { text: 'Personal starting plan ready' }
    ]
  },
  just_a_moment: {
    hide_progress: true
  },
  good_hands: {
    hide_progress: true,
    title: "You're in good hands",
    cta: 'Continue'
  },
  preparation_time: {
    title: 'How much time do you have for meal preparation each day?',
    options: {
      [MEALPLAN_PREP_TIME.LESS30MIN]: {
        title: 'Up to 30 minutes'
      },
      [MEALPLAN_PREP_TIME.LESS60MIN]: {
        title: 'Up to 1 hour'
      },
      [MEALPLAN_PREP_TIME.MORE60MIN]: {
        title: 'More than 1 hour'
      }
    }
  },
  meat_preference: {
    title: 'Which meat do you prefer?',
    options: {
      [MEALPLAN_MEAT.ALL]: {
        title: 'All in one',
        exclusive: true
      },
      [MEALPLAN_MEAT.CHICKEN]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.CHICKEN],
        title: 'Chicken',
        checkedTitleTemplate: foodCheckedTemplate('chicken')
      },
      [MEALPLAN_MEAT.PORK]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.PORK],
        title: 'Pork',
        checkedTitleTemplate: foodCheckedTemplate('pork')
      },
      [MEALPLAN_MEAT.BACON]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.BACON],
        title: 'Bacon',
        checkedTitleTemplate: foodCheckedTemplate('bacon')
      },
      [MEALPLAN_MEAT.BEEF]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.BEEF],
        title: 'Beef',
        checkedTitleTemplate: foodCheckedTemplate('beef')
      },
      [MEALPLAN_MEAT.TURKEY]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.TURKEY],
        title: 'Turkey',
        checkedTitleTemplate: foodCheckedTemplate('turkey')
      },
      [MEALPLAN_MEAT.FISH]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.FISH],
        title: 'Fish',
        checkedTitleTemplate: foodCheckedTemplate('fish')
      },
      [MEALPLAN_MEAT.LAMB]: {
        icon: MEALPLAN_MEAT_EMOJI[MEALPLAN_MEAT.LAMB],
        title: 'Lamb',
        checkedTitleTemplate: foodCheckedTemplate('lamb')
      },
      [MEALPLAN_MEAT.VEGETARIAN]: {
        icon: '🍆',
        title: 'I’m a vegetarian',
        exclusive: true
      }
    }
  },
  food_preference: {
    title: 'What are your food preferences?',
    subtitle: 'Choose at least 5 products',
    options: {
      [MEALPLAN_FOOD.ALL]: {
        title: 'All in one',
        exclusive: true
      },
      [MEALPLAN_FOOD.AVOCADOS]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.AVOCADOS],
        title: 'Avocados',
        checkedTitleTemplate: foodCheckedTemplate('avocado')
      },
      [MEALPLAN_FOOD.EGGS]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.EGGS],
        title: 'Eggs',
        checkedTitleTemplate: foodCheckedTemplate('egg')
      },
      [MEALPLAN_FOOD.MUSHROOMS]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.MUSHROOMS],
        title: 'Mushrooms',
        checkedTitleTemplate: foodCheckedTemplate('mushroom')
      },
      [MEALPLAN_FOOD.ONIONS]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.ONIONS],
        title: 'Onions',
        checkedTitleTemplate: foodCheckedTemplate('onion')
      },
      [MEALPLAN_FOOD.CHEESE]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.CHEESE],
        title: 'Cheese',
        checkedTitleTemplate: foodCheckedTemplate('cheese')
      },
      [MEALPLAN_FOOD.NUTS]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.NUTS],
        title: 'Nuts',
        checkedTitleTemplate: foodCheckedTemplate('nut')
      },
      [MEALPLAN_FOOD.BUTTER]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.BUTTER],
        title: 'Butter',
        checkedTitleTemplate: foodCheckedTemplate('butter')
      },
      [MEALPLAN_FOOD.COCONUT]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.COCONUT],
        title: 'Coconut',
        checkedTitleTemplate: foodCheckedTemplate('coconut')
      },
      [MEALPLAN_FOOD.MILK]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.MILK],
        title: 'Milk',
        checkedTitleTemplate: foodCheckedTemplate('milk')
      },
      [MEALPLAN_FOOD.SEAFOOD]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.SEAFOOD],
        title: 'Seafood',
        checkedTitleTemplate: foodCheckedTemplate('seafood')
      },
      [MEALPLAN_FOOD.OLIVES]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.OLIVES],
        title: 'Olives',
        checkedTitleTemplate: foodCheckedTemplate('olives')
      },
      [MEALPLAN_FOOD.TOFU]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.TOFU],
        title: 'Tofu',
        checkedTitleTemplate: foodCheckedTemplate('tofu')
      },
      [MEALPLAN_FOOD.COTTAGE_CHEESE]: {
        icon: MEALPLAN_FOOD_EMOJI[MEALPLAN_FOOD.COTTAGE_CHEESE],
        title: 'Cottage cheese',
        checkedTitleTemplate: foodCheckedTemplate('cottage cheese')
      }
    }
  },
  allergies: {
    title: 'Do you have any food allergies or intolerance?',
    options: {
      [ALLERGIES.NONE]: {
        title: 'None',
        exclusive: true
      },
      [ALLERGIES.LACTOSE]: {
        title: 'Lactose',
        checkedTitleTemplate: allergyCheckedTemplate('lactose')
      },
      [ALLERGIES.NUTS]: {
        title: 'Nuts',
        checkedTitleTemplate: allergyCheckedTemplate('nut')
      },
      [ALLERGIES.MILK_PROTEIN]: {
        title: 'Milk protein',
        checkedTitleTemplate: allergyCheckedTemplate('milk protein')
      },
      [ALLERGIES.EGG_PROTEIN]: {
        title: 'Egg protein',
        checkedTitleTemplate: allergyCheckedTemplate('egg protein')
      },
      [ALLERGIES.HONEY]: {
        title: 'Honey',
        checkedTitleTemplate: allergyCheckedTemplate('honey')
      },
      [ALLERGIES.FISH]: {
        title: 'Fish',
        checkedTitleTemplate: allergyCheckedTemplate('fish')
      },
      [ALLERGIES.CITRUS]: {
        title: 'Citrus fruits',
        checkedTitleTemplate: allergyCheckedTemplate('citrus fruits')
      },
      [ALLERGIES.NOT_ANSWER]: {
        title: 'Prefer not to answer'
      }
    }
  },
  food_feedback: {
    title: 'We already know what your diet will be!\n Keep going!'
  },
  motivation: {
    title: 'How motivated are you to lose weight?',
    options: {
      try_keto: {
        title: 'I just want to try the keto'
      },
      lose_weight: {
        title: 'I want to ty keto and lose some weight'
      },
      lose_max_weight: {
        title: 'I want to lose the maximum amount of weight'
      },
      dont_know_yet: {
        title: 'Don’t know yet'
      }
    }
  },
  measurements: {
    title: 'Мeasurements',
    cta: 'Continue'
  },
  ready_to_change_your_life: {
    hide_progress: true,
    full_width: true,
    icon: '🤝',
    title: '${name}, are you ready to change your life?',
    subtitle:
      'Let’s prepare your customized weight loss plan and start building strong wellness habits!',
    cta: 'I AM READY'
  },
  keto_is_different: {
    hide_progress: true,
    full_width: true,
    icon: '🎉',
    title: 'Keto is different',
    subtitle:
      'The vast majority of people successfully lost weight over the first few weeks, but failed to maintain their weight for more than 3 months.<br /><br />Keto Diet App makes sure you not only lose weight, but also maintain your progress successfully.',
    cta: 'Continue'
  },
  congratulations: {
    hide_progress: true,
    full_width: true,
    icon: '🎉',
    title: 'Congratulations!',
    subtitle:
      'You’ve just set your first goal! You can always change it later.',
    cta: 'Continue'
  },
  achieve_your_goals_faster: {
    hide_progress: true,
    full_width: true,
    icon: '🚀',
    title: 'You can achieve your goals faster!',
    subtitle:
      'Now let’s see which habits are working for you and which aren’t.',
    cta: 'Continue'
  },
  doing_better_than_you_think: {
    hide_progress: true,
    full_width: true,
    icon: '🚀',
    title: 'You’re doing better than you think!',
    subtitle:
      'We’ll help you identify what’s working and how to stick with it!',
    cta: 'Continue'
  },
  our_bodies_change_as_we_do: {
    hide_progress: true,
    full_width: true,
    icon: '✨',
    title: 'Our bodies change as we do',
    subtitle:
      'No worries. Weight often fluctuates with ups and downs that life brings.<br /><br />You can always change your habits for the better.',
    cta: 'Continue'
  },
  your_plan_needs_7_minutes_a_day: {
    hide_progress: true,
    full_width: true,
    icon: '⏰',
    title: 'Your plan needs 7 minutes a day',
    subtitle:
      'Small changes lead to big results, but only when the plan matches your needs and schedule.',
    cta: 'Continue'
  },
  work_on_this_together: {
    hide_progress: true,
    full_width: true,
    icon: '✨',
    title: 'We’ll work on this together!',
    subtitle:
      'There are thousands of reasons you might have trouble managing your nutrition.<br><br>Let’s figure out how you can change this.',
    cta: 'Continue'
  },
  your_goals_create_your_reality: {
    hide_progress: true,
    full_width: true,
    icon: '⭐',
    title: 'Your goals create your reality',
    subtitle:
      'Aim high and be proud of every small achievement! This is YOUR journey!',
    cta: 'Continue'
  },
  one_step_at_a_time: {
    hide_progress: true,
    full_width: true,
    icon: '✨',
    title: 'One step at a time',
    subtitle:
      'Wellness is not only about what you eat but also about how you think.<br><br>Keto Diet App will help you to stay motivated.',
    cta: 'Continue'
  },
  calculating_your_personal_plan: {
    hide_progress: true,
    title: 'Calculating your personal plan',
    subtitle:
      'We’ve helped 147.165 ${gender} in their ${age}s lose over 396 tons of unwanted weight ',
    items: [
      {
        title: 'Analyzing your profile',
        title_mid: 'Analyzing your profile',
        title_low: 'Analyzing your profile',
        duration: 10.0
      }
    ]
  },
  great: {
    hide_progress: true,
    full_width: true,
    icon: '🎉',
    title: 'Great!',
    subtitle:
      'Motivation is key when it comes to losing weight, especially in the first three months. This is how long it takes for our body to form new healthy nutrition habits.',
    cta: 'Continue'
  },
  great_2: {
    hide_progress: true,
    full_width: true,
    icon: '🎉',
    title: 'Great!',
    subtitle:
      'Motivation is key when it comes to losing weight, especially in the first three months. This is how long it takes for our body to form new healthy nutrition habits.',
    cta: 'Next'
  },
  halfway_there: {
    hide_progress: true,
    full_width: true,
    icon: '✨',
    title: 'Halfway there!',
    subtitle:
      'We’ve created a basic plan for you.<br><br>Now let’s customize it. Please take your time while answering the following questions. Every answer will slightly influence your personal plan.',
    cta: 'Continue'
  },
  eating_habits: {
    title: 'How would you describe your eating habits?',
    options: {
      same_foods: {
        title: 'I eat the same food every day'
      },
      similar_foods: {
        title: 'I eat similar foods, with some variation'
      },
      familiar_foods: {
        title: 'I rotate between familiar foods'
      },
      different_foods: {
        title: 'I like to experiment with different foods'
      }
    }
  },
  quality_of_your_sleep: {
    title: 'How would you rate the quality of your sleep?',
    options: {
      trouble_falling_asleep: {
        title: 'I have trouble falling asleep'
      },
      varies: {
        title: 'It varies'
      },
      sleep_well: {
        title: 'I sleep well most nights'
      }
    }
  },
  work_schedule: {
    title: 'What’s your work schedule?',
    options: {
      flexible: {
        title: 'Flexible'
      },
      nine_to_five: {
        title: 'Nine to five'
      },
      shifts: {
        title: 'Shifts'
      },
      between_jobs_or_unemployed: {
        title: 'Between jobs or unemployed'
      },
      seasonal: {
        title: 'Seasonal'
      },
      retired: {
        title: 'Retired'
      },
      other: {
        title: 'Other'
      }
    }
  },
  daily_water_intake: {
    title: 'How many glasses of water do you drink per day?',
    options: {
      '2_glasses': { icon: '💧', title: 'Less than 2 glasses' },
      '2_5_glasses': { icon: '💦', title: 'About 3-6 glasses' },
      '5_glasses': { icon: '🌊', title: 'More than 6 glasses' }
    }
  },
  harder_make_healthy_choices: {
    title: 'Is it hard for you to make healthy choices during the weekend?',
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    }
  },
  meals_each_day: {
    title: 'Do you typically eat meals around the same time each day?',
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    }
  },
  almost_done: {
    hide_progress: true,
    subtitle:
      'We’re almost finished customizing your plan.<br /><br />Tell us more about your health conditions so we can further personalize your plan.',
    icon: '🏁',
    title: 'Almost done'
  },
  taking_any_medcation: {
    options: {
      vitamins: { title: 'Vitamins' },
      hormones: { title: 'Hormones' },
      antibiotics: { title: 'Antibiotics' },
      anxiety_medications: { title: 'Anxiety medications' },
      no: { title: 'No' }
    },
    title: 'Are you taking any medications?'
  },
  dietary_restrictions: {
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    title: 'Do you have any dietary restrictions?'
  },
  are_you_at_risk: {
    cta_title: 'Continue',
    title: 'Are you at risk of any of the following?',
    subtitle: 'You can select multiple options',
    options: {
      blood_pressure: { title: 'High blood pressure' },
      heart_disease: { title: 'Heart disease or stroke' },
      diabetes: { title: 'Diabetes' },
      osteoarthritis: { title: 'Osteoarthritis' },
      kidney_disease: { title: 'Kidney disease' },
      depression: { title: 'Depression' },
      none: { title: 'None of these' }
    }
  },
  thank_you_for_sharing: {
    hide_progress: true,
    subtitle:
      'We know that losing weight can be challenging. <br /><br />Remember, this is not about the number on the scale, it’s about being a better and healthier version of yourself. You can do this 💪\n',
    icon: '🤗',
    title: 'Thank you for sharing!',
    cta: 'Continue'
  },
  qoute_weight_impact: {
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    subtitle: 'Do you agree with the statement above?',
    title: '"My weight impacts my self-esteem"'
  },
  qoute_family_concern: {
    subtitle: 'Do you agree with the statement above?',
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    title: '"My family is concerned about my health"'
  },
  qoute_motivation: {
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    subtitle: 'Do you agree with the statement above?',
    title: '"I am currently extremely motivated to reach my target weight"'
  },
  quote_self_esteem: {
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    subtitle: '"My weight impacts my self-esteem"',
    title: 'Do you agree with the statement below?'
  },
  quote_my_health: {
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    title: 'Do you agree with the statement below?',
    subtitle: '"My family is concerned about my health"'
  },
  quote_my_target_weight: {
    options: {
      yes: { title: 'Yes' },
      no: { title: 'No' }
    },
    title: 'Do you agree with the statement below?',
    subtitle: '"I am extremely motivated to reach my target weight"'
  },
  help_lose_weight: {
    title: 'Have you attempted any of the following to help lose weight?',
    options: {
      paid_meal_plans: { title: 'Paid meal plans' },
      restrictive_dieting: { title: 'Restrictive dieting' },
      gym_membership: { title: 'Gym membership' },
      none: { title: 'None' }
    }
  },
  live_events_led_to_weight: {
    title: 'Have any life events led to weight gain in the last few years?',
    options: {
      none: { title: 'None' },
      marriage_or_relationship: { title: 'Marriage or relationship' },
      pregnancy: { title: 'Pregnancy' },
      busier_work_and_family_life: { title: 'Busier work & family life' },
      stress: { title: 'Stress' },
      medication_or_hormonal_disorder: {
        title: 'Medication or Hormonal Disorder'
      },
      other: { title: 'Other' }
    }
  },
  keto_is_delicious: {
    hide_progress: true,
    full_width: true,
    icon: '🍳',
    title: 'Keto is delicious!',
    subtitle:
      'Keto Diet App includes recipies of different level of complexity, you can have amazing meals, enjoy your favorite foods and lose weight at the same time!',
    cta: 'Continue'
  },
  great_source_of_fat: {
    hide_progress: true,
    full_width: true,
    icon: '🎉',
    title: "You're right!",
    options: {
      coconut: {
        title:
          'Correct! Coconut is a great source of fat - essential for providing energy when on the keto diet. In fact, most of the energy provided by raw coconut comes from fat: one cup contains 26.8 g of saturated fat. Even avocado scores lower!'
      },
      avocado: {
        title:
          'Almost! Whereas most fruits primarily contain carbs, avocados are loaded with fats. In fact, avocados are about 77% fat, by calories, making them even higher in fat than most animal foods! But did you know that coconut is also considered a fruit and contains 26.8 g of saturated fat per cup?'
      }
    },
    cta: 'Continue'
  },
  health_benefits: {
    title: 'Among other health benefits, keto diet is known for…',
    options: {
      boosting_your_energy: { icon: '🤩', title: 'Boosting your energy' },
      making_you_hungry: { icon: '😖', title: 'Making you hungry' }
    }
  },
  health_benefits_answer: {
    hide_progress: true,
    full_width: true,
    icon: '🎉',
    options: {
      boosting_your_energy: {
        title: "You're right!",
        subtitle:
          'Not only can keto improve your cholesterol, blood pressure and blood sugar, but it also reduces your appetite, boosts weight loss, enhances your cognitive function and helps fight acne.'
      },
      making_you_hungry: {
        title: 'Almost!',
        subtitle:
          'Ketones suppress ghrelin — your hunger hormone. Reduced appetite means you spend longer periods without eating, which encourages your body to start using fat as a source of energy.'
      }
    },
    cta: 'Continue'
  },
  name: {
    title: 'What’s your name?',
    cta: 'Continue'
  },
  which_best_describes_you: {
    title: 'Which best describes you?',
    cta: 'Continue',
    options: {
      significantImprovement: {
        title: 'My health-related habits need a significant improvement '
      },
      healthy_habits: {
        title: 'I have some healthy habits'
      },
      eatHealthyFoodAndExercise: {
        title: 'I regulary eat healthy food and exercise'
      }
    }
  },
  healthy_choices: {
    title: 'Is it hard for you to make healthy choices?',
    cta: 'Continue',
    options: {
      always: {
        title: 'Yes, always'
      },
      sometimes: {
        title: 'Yes, sometimes'
      },
      no: {
        title: 'No'
      }
    }
  },
  typically_eat_meals: {
    title: 'Do you typically eat meals around the same time each day?',
    cta: 'Continue',
    options: {
      yes: {
        title: 'Yes'
      },
      no: {
        title: 'No'
      }
    }
  },
  meal_preparation_each_day: {
    title: 'How much time do you have for meal preparation each day?',
    cta: 'Continue',
    options: {
      up_to_30_minutes: {
        title: 'Up to 30 minutes'
      },
      up_to_1_hour: {
        title: 'Up to 1 hour'
      },
      more_than_1_hour: {
        title: 'More than 1 hour'
      }
    }
  },
  achieve_in_addition: {
    title: 'What goal would you like to achieve in addition to weight loss?',
    cta: 'Continue',
    options: {
      run_a_5k: {
        title: 'Run a 5k'
      },
      be_able_to_do_outdoor_activities: {
        title: 'Be able to do outdoor activities'
      },
      feel_more_comfortable_in_my_body: {
        title: 'Feel more comfortable in my body'
      },
      feel_healthier_and_increase_energy: {
        title: 'Feel healthier and increase energy'
      },
      reverse_a_chronic_health_condition: {
        title: 'Reverse a chronic health condition'
      },
      prepare_myself_for_a_special_event: {
        title: 'Prepare myself for a special event'
      },
      sleep_well: {
        title: 'Sleep well '
      },
      look_better_in_clothes: {
        title: 'Look better in clothes'
      }
    }
  }
}
