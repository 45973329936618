export const UNIT_SYSTEM = Object.freeze({
  IMPERIAL: 'imperial',
  METRIC: 'metric'
})

export const KG_TO_LBS = 0.453592
export const CM_TO_FT = 0.0328

export const EXPERIMENTS = {
  DESIGN: {
    NO_PASSWORD: 'no-password',
    EXTENDED_CARD: 'extended-card',
    LARGE_TEXT: 'large-text',
    GRAPH: 'graph'
  },
  ONBOARDING: {
    V1: 'v1',
    V1_1: 'v1.1',
    V1_2: 'v1.2',
    V1_3: 'v1.3',
    V1_4: 'v1.4',
    V1_4_TRIAL_V1_1: 'v1.4_trial_v1.1',
    V1_5: 'v1.5',
    V1_6: 'v1.6',
    V1_7_TRIAL_V1_1: 'v1.7_trial_v1.1',
    V1_8_TRIAL_V1_1: 'v1.8_trial_v1.1',
    V1_9_TRIAL_V1_1: 'v1.9_trial_v1.1',
    V2_0_TRIAL_V1_1: 'v2.0_trial_v1.1',
    V2_0_TRIAL_V1_2: 'v2.0_trial_v1.2',
    V2_0_TRIAL_V1_3: 'v2.0_trial_v1.3',
    V2_0_TRIAL_V1_4: 'v2.0_trial_v1.4',
    V2_0_INTRODUCTORY_V0_1: 'v2.0_introductory_v0.1',
    V2_1_INTRODUCTORY_V0_1: 'v2.1_introductory_v0.1',
    V2_2_INTRODUCTORY_V0_1: 'v2.2_introductory_v0.1',
    V2_3_INTRODUCTORY_V0_1: 'v2.3_introductory_v0.1',
    V2_0_INTRODUCTORY_V0_2: 'v2.0_introductory_v0.2',
    MEAL_PLAN: 'meal_plan'
  }
}

export const PAYAMENT_GATES = {
  CARD: 'card',
  PAYPAL: 'paypal'
}

export const MEALPLAN_PREP_TIME = {
  LESS30MIN: 'less30min',
  LESS60MIN: 'less60min',
  MORE60MIN: 'more60min'
}

export const MEALPLAN_MEAT = {
  ALL: 'all',
  CHICKEN: 'chicken',
  PORK: 'pork',
  BACON: 'bacon',
  BEEF: 'beef',
  FISH: 'fish',
  LAMB: 'lamb',
  TURKEY: 'turkey',
  VEGETARIAN: 'vegetarian'
}

export const MEALPLAN_FOOD = {
  ALL: 'all',
  AVOCADOS: 'avocados',
  EGGS: 'eggs',
  MUSHROOMS: 'mushrooms',
  ONIONS: 'onions',
  CHEESE: 'cheese',
  NUTS: 'nuts',
  BUTTER: 'butter',
  COCONUT: 'coconut',
  MILK: 'milk',
  SEAFOOD: 'seafood',
  OLIVES: 'olives',
  TOFU: 'tofu',
  COTTAGE_CHEESE: 'cottage_cheese'
}

export const ALLERGIES = {
  NONE: 'none',
  LACTOSE: 'lactose',
  NUTS: 'nuts',
  MILK_PROTEIN: 'milk_protein',
  EGG_PROTEIN: 'egg_protein',
  HONEY: 'honey',
  FISH: 'fish',
  CITRUS: 'citrus',
  NOT_ANSWER: 'not_answer'
}

export const TITLE = {
  KETO_MEAL_PLAN: 'Keto Meal Plan',
  KETO_DIET_APP: 'Keto Diet App'
}

export const MEALPLAN_MEAT_EMOJI = {
  [MEALPLAN_MEAT.CHICKEN]: '🐓',
  [MEALPLAN_MEAT.PORK]: '🐖',
  [MEALPLAN_MEAT.BACON]: '🥓',
  [MEALPLAN_MEAT.BEEF]: '🐂',
  [MEALPLAN_MEAT.TURKEY]: '🦃',
  [MEALPLAN_MEAT.FISH]: '🐟',
  [MEALPLAN_MEAT.LAMB]: '🐑'
}

export const MEALPLAN_FOOD_EMOJI = {
  [MEALPLAN_FOOD.AVOCADOS]: '🥑',
  [MEALPLAN_FOOD.EGGS]: '🥚',
  [MEALPLAN_FOOD.MUSHROOMS]: '🍄',
  [MEALPLAN_FOOD.ONIONS]: '🧅',
  [MEALPLAN_FOOD.CHEESE]: '🧀',
  [MEALPLAN_FOOD.NUTS]: '🌰',
  [MEALPLAN_FOOD.BUTTER]: '🧈',
  [MEALPLAN_FOOD.COCONUT]: '🥥',
  [MEALPLAN_FOOD.MILK]: '🥛',
  [MEALPLAN_FOOD.SEAFOOD]: '🦐',
  [MEALPLAN_FOOD.OLIVES]: '🍈',
  [MEALPLAN_FOOD.TOFU]: '🍢',
  [MEALPLAN_FOOD.COTTAGE_CHEESE]: '🍚'
}

export const MEALPLAN_DEFAULT_EMOJI = ['🥒', '🍅', '🥬', '🥦', '🍓']

export const WHITE_LIST_QUERY_FROM_FTP = ['gender', 'keto_goal']
