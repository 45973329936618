<script>
import { mapState } from 'vuex'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import TermsConditions from '@/components/TermsConditions'
import MoneyBackPolicy from '@/components/MoneyBackPolicy'

export default {
  name: 'LandingLayout',
  components: {
    PrivacyPolicy,
    TermsConditions,
    MoneyBackPolicy
  },
  computed: {
    ...mapState('experiment', ['designs'])
  }
}
</script>

<template>
  <main :class="designs.join(' ')">
    <nuxt class="main" />
    <client-only>
      <PrivacyPolicy />
      <TermsConditions />
      <MoneyBackPolicy />
    </client-only>
  </main>
</template>

<style lang="scss">
@import '~bulma/sass/utilities/controls';
@import '~bulma/sass/utilities/extends';
@import '~bulma/sass/base/_all';
@import '~bulma/sass/elements/button';
@import '~bulma/sass/form/shared';
@import '~bulma/sass/helpers/_all';
@import '~assets/scss/icons';
</style>
