import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _38ae76be = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _3e591c20 = () => interopDefault(import('../pages/download_app.vue' /* webpackChunkName: "pages/download_app" */))
const _76adf69f = () => interopDefault(import('../pages/finish_registration.vue' /* webpackChunkName: "pages/finish_registration" */))
const _63239e5f = () => interopDefault(import('../pages/meal_plan.vue' /* webpackChunkName: "pages/meal_plan" */))
const _03716890 = () => interopDefault(import('../pages/meal_plan_pp.vue' /* webpackChunkName: "pages/meal_plan_pp" */))
const _91b2b49a = () => interopDefault(import('../pages/meal_plan_register.vue' /* webpackChunkName: "pages/meal_plan_register" */))
const _de6d514e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _4fb206d7 = () => interopDefault(import('../pages/resend_meal_plan.vue' /* webpackChunkName: "pages/resend_meal_plan" */))
const _1655f7ad = () => interopDefault(import('../pages/s.vue' /* webpackChunkName: "pages/s" */))
const _568a1bf4 = () => interopDefault(import('../pages/s/achieve_in_addition.vue' /* webpackChunkName: "pages/s/achieve_in_addition" */))
const _0b09cc24 = () => interopDefault(import('../pages/s/achieve_your_goals_faster.vue' /* webpackChunkName: "pages/s/achieve_your_goals_faster" */))
const _fd914b3e = () => interopDefault(import('../pages/s/activity.vue' /* webpackChunkName: "pages/s/activity" */))
const _7f39839d = () => interopDefault(import('../pages/s/age.vue' /* webpackChunkName: "pages/s/age" */))
const _250d0a88 = () => interopDefault(import('../pages/s/allergies.vue' /* webpackChunkName: "pages/s/allergies" */))
const _1318d991 = () => interopDefault(import('../pages/s/almost_done.vue' /* webpackChunkName: "pages/s/almost_done" */))
const _6d567224 = () => interopDefault(import('../pages/s/are_you_at_risk.vue' /* webpackChunkName: "pages/s/are_you_at_risk" */))
const _d03dc934 = () => interopDefault(import('../pages/s/authors.vue' /* webpackChunkName: "pages/s/authors" */))
const _677d0916 = () => interopDefault(import('../pages/s/body_shape.vue' /* webpackChunkName: "pages/s/body_shape" */))
const _3b079597 = () => interopDefault(import('../pages/s/calculating_plan.vue' /* webpackChunkName: "pages/s/calculating_plan" */))
const _740d2f57 = () => interopDefault(import('../pages/s/calculating_plan_v5.vue' /* webpackChunkName: "pages/s/calculating_plan_v5" */))
const _18ed6d2a = () => interopDefault(import('../pages/s/calculating_your_personal_plan.vue' /* webpackChunkName: "pages/s/calculating_your_personal_plan" */))
const _3d06d21a = () => interopDefault(import('../pages/s/congratulations.vue' /* webpackChunkName: "pages/s/congratulations" */))
const _3dbf45e8 = () => interopDefault(import('../pages/s/daily_water_intake.vue' /* webpackChunkName: "pages/s/daily_water_intake" */))
const _a4beed38 = () => interopDefault(import('../pages/s/dietary_restrictions.vue' /* webpackChunkName: "pages/s/dietary_restrictions" */))
const _4383e1b9 = () => interopDefault(import('../pages/s/doing_better_than_you_think.vue' /* webpackChunkName: "pages/s/doing_better_than_you_think" */))
const _ff005b1c = () => interopDefault(import('../pages/s/eating_habits.vue' /* webpackChunkName: "pages/s/eating_habits" */))
const _d8620c38 = () => interopDefault(import('../pages/s/food_feedback.vue' /* webpackChunkName: "pages/s/food_feedback" */))
const _7250ba7a = () => interopDefault(import('../pages/s/food_preference.vue' /* webpackChunkName: "pages/s/food_preference" */))
const _ed3c10da = () => interopDefault(import('../pages/s/gender.vue' /* webpackChunkName: "pages/s/gender" */))
const _54948f34 = () => interopDefault(import('../pages/s/good_hands.vue' /* webpackChunkName: "pages/s/good_hands" */))
const _440cceaa = () => interopDefault(import('../pages/s/great.vue' /* webpackChunkName: "pages/s/great" */))
const _d4954bc4 = () => interopDefault(import('../pages/s/great_2.vue' /* webpackChunkName: "pages/s/great_2" */))
const _2be487c1 = () => interopDefault(import('../pages/s/great_source_of_fat.vue' /* webpackChunkName: "pages/s/great_source_of_fat" */))
const _53b2a85f = () => interopDefault(import('../pages/s/halfway_there.vue' /* webpackChunkName: "pages/s/halfway_there" */))
const _48922a24 = () => interopDefault(import('../pages/s/harder_make_healthy_choices.vue' /* webpackChunkName: "pages/s/harder_make_healthy_choices" */))
const _7fdac6dd = () => interopDefault(import('../pages/s/health_benefits.vue' /* webpackChunkName: "pages/s/health_benefits" */))
const _0cebbd10 = () => interopDefault(import('../pages/s/health_benefits_answer.vue' /* webpackChunkName: "pages/s/health_benefits_answer" */))
const _69e43b6e = () => interopDefault(import('../pages/s/healthy_choices.vue' /* webpackChunkName: "pages/s/healthy_choices" */))
const _1a32f2f9 = () => interopDefault(import('../pages/s/height.vue' /* webpackChunkName: "pages/s/height" */))
const _2c3335b6 = () => interopDefault(import('../pages/s/help_lose_weight.vue' /* webpackChunkName: "pages/s/help_lose_weight" */))
const _601dc7af = () => interopDefault(import('../pages/s/just_a_moment.vue' /* webpackChunkName: "pages/s/just_a_moment" */))
const _5d28b51e = () => interopDefault(import('../pages/s/keto_familiarity.vue' /* webpackChunkName: "pages/s/keto_familiarity" */))
const _821eb6c8 = () => interopDefault(import('../pages/s/keto_is_delicious.vue' /* webpackChunkName: "pages/s/keto_is_delicious" */))
const _361002ac = () => interopDefault(import('../pages/s/keto_is_different.vue' /* webpackChunkName: "pages/s/keto_is_different" */))
const _d4d7ff86 = () => interopDefault(import('../pages/s/last_time_ideal_weight.vue' /* webpackChunkName: "pages/s/last_time_ideal_weight" */))
const _755cfb73 = () => interopDefault(import('../pages/s/live_events_led_to_weight.vue' /* webpackChunkName: "pages/s/live_events_led_to_weight" */))
const _3caceec0 = () => interopDefault(import('../pages/s/meal_preparation_each_day.vue' /* webpackChunkName: "pages/s/meal_preparation_each_day" */))
const _41dc7f7f = () => interopDefault(import('../pages/s/meals_each_day.vue' /* webpackChunkName: "pages/s/meals_each_day" */))
const _75b28289 = () => interopDefault(import('../pages/s/measurements.vue' /* webpackChunkName: "pages/s/measurements" */))
const _141a4b6d = () => interopDefault(import('../pages/s/meat_preference.vue' /* webpackChunkName: "pages/s/meat_preference" */))
const _532727e8 = () => interopDefault(import('../pages/s/motivation.vue' /* webpackChunkName: "pages/s/motivation" */))
const _fce41e46 = () => interopDefault(import('../pages/s/name.vue' /* webpackChunkName: "pages/s/name" */))
const _65f38ae2 = () => interopDefault(import('../pages/s/one_step_at_a_time.vue' /* webpackChunkName: "pages/s/one_step_at_a_time" */))
const _f9818bb8 = () => interopDefault(import('../pages/s/our_bodies_change_as_we_do.vue' /* webpackChunkName: "pages/s/our_bodies_change_as_we_do" */))
const _32a655c7 = () => interopDefault(import('../pages/s/preparation_time.vue' /* webpackChunkName: "pages/s/preparation_time" */))
const _1f7c5624 = () => interopDefault(import('../pages/s/qoute_family_concern.vue' /* webpackChunkName: "pages/s/qoute_family_concern" */))
const _a8334282 = () => interopDefault(import('../pages/s/qoute_motivation.vue' /* webpackChunkName: "pages/s/qoute_motivation" */))
const _44fabad4 = () => interopDefault(import('../pages/s/qoute_weight_impact.vue' /* webpackChunkName: "pages/s/qoute_weight_impact" */))
const _cae741de = () => interopDefault(import('../pages/s/quality_of_your_sleep.vue' /* webpackChunkName: "pages/s/quality_of_your_sleep" */))
const _60e5496a = () => interopDefault(import('../pages/s/quote_my_health.vue' /* webpackChunkName: "pages/s/quote_my_health" */))
const _02139570 = () => interopDefault(import('../pages/s/quote_my_target_weight.vue' /* webpackChunkName: "pages/s/quote_my_target_weight" */))
const _5fff07b5 = () => interopDefault(import('../pages/s/quote_self_esteem.vue' /* webpackChunkName: "pages/s/quote_self_esteem" */))
const _4afb837f = () => interopDefault(import('../pages/s/ready_to_change_your_life.vue' /* webpackChunkName: "pages/s/ready_to_change_your_life" */))
const _0a3b084b = () => interopDefault(import('../pages/s/risks_list.vue' /* webpackChunkName: "pages/s/risks_list" */))
const _40613bd7 = () => interopDefault(import('../pages/s/success_story.vue' /* webpackChunkName: "pages/s/success_story" */))
const _dd84adfc = () => interopDefault(import('../pages/s/taking_any_medcation.vue' /* webpackChunkName: "pages/s/taking_any_medcation" */))
const _581d9c0f = () => interopDefault(import('../pages/s/thank_you_for_sharing.vue' /* webpackChunkName: "pages/s/thank_you_for_sharing" */))
const _6a8093ca = () => interopDefault(import('../pages/s/weight.vue' /* webpackChunkName: "pages/s/weight" */))
const _634daf98 = () => interopDefault(import('../pages/s/weight_goal.vue' /* webpackChunkName: "pages/s/weight_goal" */))
const _6855049e = () => interopDefault(import('../pages/s/weight_loss_preview.vue' /* webpackChunkName: "pages/s/weight_loss_preview" */))
const _2f68ab21 = () => interopDefault(import('../pages/s/what_is_keto.vue' /* webpackChunkName: "pages/s/what_is_keto" */))
const _72894550 = () => interopDefault(import('../pages/s/what_is_keto_hide.vue' /* webpackChunkName: "pages/s/what_is_keto_hide" */))
const _05ccd7fe = () => interopDefault(import('../pages/s/which_best_describes_you.vue' /* webpackChunkName: "pages/s/which_best_describes_you" */))
const _c8c13832 = () => interopDefault(import('../pages/s/work_on_this_together.vue' /* webpackChunkName: "pages/s/work_on_this_together" */))
const _54476fba = () => interopDefault(import('../pages/s/work_schedule.vue' /* webpackChunkName: "pages/s/work_schedule" */))
const _9725ac24 = () => interopDefault(import('../pages/s/your_goals_create_your_reality.vue' /* webpackChunkName: "pages/s/your_goals_create_your_reality" */))
const _9d0e5a70 = () => interopDefault(import('../pages/s/your_plan_needs_7_minutes_a_day.vue' /* webpackChunkName: "pages/s/your_plan_needs_7_minutes_a_day" */))
const _030ecdd0 = () => interopDefault(import('../pages/scientific_references.vue' /* webpackChunkName: "pages/scientific_references" */))
const _7760fe2f = () => interopDefault(import('../pages/story.vue' /* webpackChunkName: "pages/story" */))
const _a6583cc8 = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _0df8053b = () => interopDefault(import('../pages/offer/50off.vue' /* webpackChunkName: "pages/offer/50off" */))
const _11ee38b0 = () => interopDefault(import('../pages/offer/app.vue' /* webpackChunkName: "pages/offer/app" */))
const _452dd707 = () => interopDefault(import('../pages/offer/blackfriday_2021.vue' /* webpackChunkName: "pages/offer/blackfriday_2021" */))
const _69bc9b11 = () => interopDefault(import('../pages/offer/introductory.vue' /* webpackChunkName: "pages/offer/introductory" */))
const _00ffa3e5 = () => interopDefault(import('../pages/offer/introductory_sale.vue' /* webpackChunkName: "pages/offer/introductory_sale" */))
const _60e98dcc = () => interopDefault(import('../pages/offer/introductory_v2.vue' /* webpackChunkName: "pages/offer/introductory_v2" */))
const _291a8016 = () => interopDefault(import('../pages/offer/mealplan.vue' /* webpackChunkName: "pages/offer/mealplan" */))
const _e9a56e38 = () => interopDefault(import('../pages/offer/onboarding.vue' /* webpackChunkName: "pages/offer/onboarding" */))
const _d7df900e = () => interopDefault(import('../pages/offer/paywall.vue' /* webpackChunkName: "pages/offer/paywall" */))
const _1eb19840 = () => interopDefault(import('../pages/offer/results/index.vue' /* webpackChunkName: "pages/offer/results/index" */))
const _f6ef6280 = () => interopDefault(import('../pages/offer/special.vue' /* webpackChunkName: "pages/offer/special" */))
const _0d43d65d = () => interopDefault(import('../pages/offer/trial.vue' /* webpackChunkName: "pages/offer/trial" */))
const _74ceab64 = () => interopDefault(import('../pages/offer/trial_v2.vue' /* webpackChunkName: "pages/offer/trial_v2" */))
const _74b27c62 = () => interopDefault(import('../pages/offer/trial_v3.vue' /* webpackChunkName: "pages/offer/trial_v3" */))
const _68050988 = () => interopDefault(import('../pages/mealplan/paid/_mealplan_id/index.vue' /* webpackChunkName: "pages/mealplan/paid/_mealplan_id/index" */))
const _937b17c8 = () => interopDefault(import('../pages/mealplan/paid/_mealplan_id/_day/_type_recipe.vue' /* webpackChunkName: "pages/mealplan/paid/_mealplan_id/_day/_type_recipe" */))
const _99c4d1e2 = () => interopDefault(import('../pages/mealplan/_mealplan_id/index.vue' /* webpackChunkName: "pages/mealplan/_mealplan_id/index" */))
const _055c1880 = () => interopDefault(import('../pages/mealplan/_mealplan_id/success.vue' /* webpackChunkName: "pages/mealplan/_mealplan_id/success" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/download",
    component: _38ae76be,
    pathToRegexpOptions: {"strict":true},
    name: "download"
  }, {
    path: "/download_app",
    component: _3e591c20,
    pathToRegexpOptions: {"strict":true},
    name: "download_app"
  }, {
    path: "/finish_registration",
    component: _76adf69f,
    pathToRegexpOptions: {"strict":true},
    name: "finish_registration"
  }, {
    path: "/meal_plan",
    component: _63239e5f,
    pathToRegexpOptions: {"strict":true},
    name: "meal_plan"
  }, {
    path: "/meal_plan_pp",
    component: _03716890,
    pathToRegexpOptions: {"strict":true},
    name: "meal_plan_pp"
  }, {
    path: "/meal_plan_register",
    component: _91b2b49a,
    pathToRegexpOptions: {"strict":true},
    name: "meal_plan_register"
  }, {
    path: "/register",
    component: _de6d514e,
    pathToRegexpOptions: {"strict":true},
    name: "register"
  }, {
    path: "/resend_meal_plan",
    component: _4fb206d7,
    pathToRegexpOptions: {"strict":true},
    name: "resend_meal_plan"
  }, {
    path: "/s",
    component: _1655f7ad,
    pathToRegexpOptions: {"strict":true},
    name: "s",
    children: [{
      path: "achieve_in_addition",
      component: _568a1bf4,
      pathToRegexpOptions: {"strict":true},
      name: "s-achieve_in_addition"
    }, {
      path: "achieve_your_goals_faster",
      component: _0b09cc24,
      pathToRegexpOptions: {"strict":true},
      name: "s-achieve_your_goals_faster"
    }, {
      path: "activity",
      component: _fd914b3e,
      pathToRegexpOptions: {"strict":true},
      name: "s-activity"
    }, {
      path: "age",
      component: _7f39839d,
      pathToRegexpOptions: {"strict":true},
      name: "s-age"
    }, {
      path: "allergies",
      component: _250d0a88,
      pathToRegexpOptions: {"strict":true},
      name: "s-allergies"
    }, {
      path: "almost_done",
      component: _1318d991,
      pathToRegexpOptions: {"strict":true},
      name: "s-almost_done"
    }, {
      path: "are_you_at_risk",
      component: _6d567224,
      pathToRegexpOptions: {"strict":true},
      name: "s-are_you_at_risk"
    }, {
      path: "authors",
      component: _d03dc934,
      pathToRegexpOptions: {"strict":true},
      name: "s-authors"
    }, {
      path: "body_shape",
      component: _677d0916,
      pathToRegexpOptions: {"strict":true},
      name: "s-body_shape"
    }, {
      path: "calculating_plan",
      component: _3b079597,
      pathToRegexpOptions: {"strict":true},
      name: "s-calculating_plan"
    }, {
      path: "calculating_plan_v5",
      component: _740d2f57,
      pathToRegexpOptions: {"strict":true},
      name: "s-calculating_plan_v5"
    }, {
      path: "calculating_your_personal_plan",
      component: _18ed6d2a,
      pathToRegexpOptions: {"strict":true},
      name: "s-calculating_your_personal_plan"
    }, {
      path: "congratulations",
      component: _3d06d21a,
      pathToRegexpOptions: {"strict":true},
      name: "s-congratulations"
    }, {
      path: "daily_water_intake",
      component: _3dbf45e8,
      pathToRegexpOptions: {"strict":true},
      name: "s-daily_water_intake"
    }, {
      path: "dietary_restrictions",
      component: _a4beed38,
      pathToRegexpOptions: {"strict":true},
      name: "s-dietary_restrictions"
    }, {
      path: "doing_better_than_you_think",
      component: _4383e1b9,
      pathToRegexpOptions: {"strict":true},
      name: "s-doing_better_than_you_think"
    }, {
      path: "eating_habits",
      component: _ff005b1c,
      pathToRegexpOptions: {"strict":true},
      name: "s-eating_habits"
    }, {
      path: "food_feedback",
      component: _d8620c38,
      pathToRegexpOptions: {"strict":true},
      name: "s-food_feedback"
    }, {
      path: "food_preference",
      component: _7250ba7a,
      pathToRegexpOptions: {"strict":true},
      name: "s-food_preference"
    }, {
      path: "gender",
      component: _ed3c10da,
      pathToRegexpOptions: {"strict":true},
      name: "s-gender"
    }, {
      path: "good_hands",
      component: _54948f34,
      pathToRegexpOptions: {"strict":true},
      name: "s-good_hands"
    }, {
      path: "great",
      component: _440cceaa,
      pathToRegexpOptions: {"strict":true},
      name: "s-great"
    }, {
      path: "great_2",
      component: _d4954bc4,
      pathToRegexpOptions: {"strict":true},
      name: "s-great_2"
    }, {
      path: "great_source_of_fat",
      component: _2be487c1,
      pathToRegexpOptions: {"strict":true},
      name: "s-great_source_of_fat"
    }, {
      path: "halfway_there",
      component: _53b2a85f,
      pathToRegexpOptions: {"strict":true},
      name: "s-halfway_there"
    }, {
      path: "harder_make_healthy_choices",
      component: _48922a24,
      pathToRegexpOptions: {"strict":true},
      name: "s-harder_make_healthy_choices"
    }, {
      path: "health_benefits",
      component: _7fdac6dd,
      pathToRegexpOptions: {"strict":true},
      name: "s-health_benefits"
    }, {
      path: "health_benefits_answer",
      component: _0cebbd10,
      pathToRegexpOptions: {"strict":true},
      name: "s-health_benefits_answer"
    }, {
      path: "healthy_choices",
      component: _69e43b6e,
      pathToRegexpOptions: {"strict":true},
      name: "s-healthy_choices"
    }, {
      path: "height",
      component: _1a32f2f9,
      pathToRegexpOptions: {"strict":true},
      name: "s-height"
    }, {
      path: "help_lose_weight",
      component: _2c3335b6,
      pathToRegexpOptions: {"strict":true},
      name: "s-help_lose_weight"
    }, {
      path: "just_a_moment",
      component: _601dc7af,
      pathToRegexpOptions: {"strict":true},
      name: "s-just_a_moment"
    }, {
      path: "keto_familiarity",
      component: _5d28b51e,
      pathToRegexpOptions: {"strict":true},
      name: "s-keto_familiarity"
    }, {
      path: "keto_is_delicious",
      component: _821eb6c8,
      pathToRegexpOptions: {"strict":true},
      name: "s-keto_is_delicious"
    }, {
      path: "keto_is_different",
      component: _361002ac,
      pathToRegexpOptions: {"strict":true},
      name: "s-keto_is_different"
    }, {
      path: "last_time_ideal_weight",
      component: _d4d7ff86,
      pathToRegexpOptions: {"strict":true},
      name: "s-last_time_ideal_weight"
    }, {
      path: "live_events_led_to_weight",
      component: _755cfb73,
      pathToRegexpOptions: {"strict":true},
      name: "s-live_events_led_to_weight"
    }, {
      path: "meal_preparation_each_day",
      component: _3caceec0,
      pathToRegexpOptions: {"strict":true},
      name: "s-meal_preparation_each_day"
    }, {
      path: "meals_each_day",
      component: _41dc7f7f,
      pathToRegexpOptions: {"strict":true},
      name: "s-meals_each_day"
    }, {
      path: "measurements",
      component: _75b28289,
      pathToRegexpOptions: {"strict":true},
      name: "s-measurements"
    }, {
      path: "meat_preference",
      component: _141a4b6d,
      pathToRegexpOptions: {"strict":true},
      name: "s-meat_preference"
    }, {
      path: "motivation",
      component: _532727e8,
      pathToRegexpOptions: {"strict":true},
      name: "s-motivation"
    }, {
      path: "name",
      component: _fce41e46,
      pathToRegexpOptions: {"strict":true},
      name: "s-name"
    }, {
      path: "one_step_at_a_time",
      component: _65f38ae2,
      pathToRegexpOptions: {"strict":true},
      name: "s-one_step_at_a_time"
    }, {
      path: "our_bodies_change_as_we_do",
      component: _f9818bb8,
      pathToRegexpOptions: {"strict":true},
      name: "s-our_bodies_change_as_we_do"
    }, {
      path: "preparation_time",
      component: _32a655c7,
      pathToRegexpOptions: {"strict":true},
      name: "s-preparation_time"
    }, {
      path: "qoute_family_concern",
      component: _1f7c5624,
      pathToRegexpOptions: {"strict":true},
      name: "s-qoute_family_concern"
    }, {
      path: "qoute_motivation",
      component: _a8334282,
      pathToRegexpOptions: {"strict":true},
      name: "s-qoute_motivation"
    }, {
      path: "qoute_weight_impact",
      component: _44fabad4,
      pathToRegexpOptions: {"strict":true},
      name: "s-qoute_weight_impact"
    }, {
      path: "quality_of_your_sleep",
      component: _cae741de,
      pathToRegexpOptions: {"strict":true},
      name: "s-quality_of_your_sleep"
    }, {
      path: "quote_my_health",
      component: _60e5496a,
      pathToRegexpOptions: {"strict":true},
      name: "s-quote_my_health"
    }, {
      path: "quote_my_target_weight",
      component: _02139570,
      pathToRegexpOptions: {"strict":true},
      name: "s-quote_my_target_weight"
    }, {
      path: "quote_self_esteem",
      component: _5fff07b5,
      pathToRegexpOptions: {"strict":true},
      name: "s-quote_self_esteem"
    }, {
      path: "ready_to_change_your_life",
      component: _4afb837f,
      pathToRegexpOptions: {"strict":true},
      name: "s-ready_to_change_your_life"
    }, {
      path: "risks_list",
      component: _0a3b084b,
      pathToRegexpOptions: {"strict":true},
      name: "s-risks_list"
    }, {
      path: "success_story",
      component: _40613bd7,
      pathToRegexpOptions: {"strict":true},
      name: "s-success_story"
    }, {
      path: "taking_any_medcation",
      component: _dd84adfc,
      pathToRegexpOptions: {"strict":true},
      name: "s-taking_any_medcation"
    }, {
      path: "thank_you_for_sharing",
      component: _581d9c0f,
      pathToRegexpOptions: {"strict":true},
      name: "s-thank_you_for_sharing"
    }, {
      path: "weight",
      component: _6a8093ca,
      pathToRegexpOptions: {"strict":true},
      name: "s-weight"
    }, {
      path: "weight_goal",
      component: _634daf98,
      pathToRegexpOptions: {"strict":true},
      name: "s-weight_goal"
    }, {
      path: "weight_loss_preview",
      component: _6855049e,
      pathToRegexpOptions: {"strict":true},
      name: "s-weight_loss_preview"
    }, {
      path: "what_is_keto",
      component: _2f68ab21,
      pathToRegexpOptions: {"strict":true},
      name: "s-what_is_keto"
    }, {
      path: "what_is_keto_hide",
      component: _72894550,
      pathToRegexpOptions: {"strict":true},
      name: "s-what_is_keto_hide"
    }, {
      path: "which_best_describes_you",
      component: _05ccd7fe,
      pathToRegexpOptions: {"strict":true},
      name: "s-which_best_describes_you"
    }, {
      path: "work_on_this_together",
      component: _c8c13832,
      pathToRegexpOptions: {"strict":true},
      name: "s-work_on_this_together"
    }, {
      path: "work_schedule",
      component: _54476fba,
      pathToRegexpOptions: {"strict":true},
      name: "s-work_schedule"
    }, {
      path: "your_goals_create_your_reality",
      component: _9725ac24,
      pathToRegexpOptions: {"strict":true},
      name: "s-your_goals_create_your_reality"
    }, {
      path: "your_plan_needs_7_minutes_a_day",
      component: _9d0e5a70,
      pathToRegexpOptions: {"strict":true},
      name: "s-your_plan_needs_7_minutes_a_day"
    }]
  }, {
    path: "/scientific_references",
    component: _030ecdd0,
    pathToRegexpOptions: {"strict":true},
    name: "scientific_references"
  }, {
    path: "/story",
    component: _7760fe2f,
    pathToRegexpOptions: {"strict":true},
    name: "story"
  }, {
    path: "/welcome",
    component: _a6583cc8,
    pathToRegexpOptions: {"strict":true},
    name: "welcome"
  }, {
    path: "/offer/50off",
    component: _0df8053b,
    pathToRegexpOptions: {"strict":true},
    name: "offer-50off"
  }, {
    path: "/offer/app",
    component: _11ee38b0,
    pathToRegexpOptions: {"strict":true},
    name: "offer-app"
  }, {
    path: "/offer/blackfriday_2021",
    component: _452dd707,
    pathToRegexpOptions: {"strict":true},
    name: "offer-blackfriday_2021"
  }, {
    path: "/offer/introductory",
    component: _69bc9b11,
    pathToRegexpOptions: {"strict":true},
    name: "offer-introductory"
  }, {
    path: "/offer/introductory_sale",
    component: _00ffa3e5,
    pathToRegexpOptions: {"strict":true},
    name: "offer-introductory_sale"
  }, {
    path: "/offer/introductory_v2",
    component: _60e98dcc,
    pathToRegexpOptions: {"strict":true},
    name: "offer-introductory_v2"
  }, {
    path: "/offer/mealplan",
    component: _291a8016,
    pathToRegexpOptions: {"strict":true},
    name: "offer-mealplan"
  }, {
    path: "/offer/onboarding",
    component: _e9a56e38,
    pathToRegexpOptions: {"strict":true},
    name: "offer-onboarding"
  }, {
    path: "/offer/paywall",
    component: _d7df900e,
    pathToRegexpOptions: {"strict":true},
    name: "offer-paywall"
  }, {
    path: "/offer/results",
    component: _1eb19840,
    pathToRegexpOptions: {"strict":true},
    name: "offer-results"
  }, {
    path: "/offer/special",
    component: _f6ef6280,
    pathToRegexpOptions: {"strict":true},
    name: "offer-special"
  }, {
    path: "/offer/trial",
    component: _0d43d65d,
    pathToRegexpOptions: {"strict":true},
    name: "offer-trial"
  }, {
    path: "/offer/trial_v2",
    component: _74ceab64,
    pathToRegexpOptions: {"strict":true},
    name: "offer-trial_v2"
  }, {
    path: "/offer/trial_v3",
    component: _74b27c62,
    pathToRegexpOptions: {"strict":true},
    name: "offer-trial_v3"
  }, {
    path: "/mealplan/paid/:mealplan_id",
    component: _68050988,
    pathToRegexpOptions: {"strict":true},
    name: "mealplan-paid-mealplan_id"
  }, {
    path: "/mealplan/paid/:mealplan_id?/:day/:type_recipe?",
    component: _937b17c8,
    pathToRegexpOptions: {"strict":true},
    name: "mealplan-paid-mealplan_id-day-type_recipe"
  }, {
    path: "/mealplan/:mealplan_id",
    component: _99c4d1e2,
    pathToRegexpOptions: {"strict":true},
    name: "mealplan-mealplan_id"
  }, {
    path: "/mealplan/:mealplan_id?/success",
    component: _055c1880,
    pathToRegexpOptions: {"strict":true},
    name: "mealplan-mealplan_id-success"
  }, {
    path: "/",
    component: _3d6810e8,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
