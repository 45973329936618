export function goalModelWithKetoRatioForCalories(calories) {
  // simple stupid :)
  // based on the table in the article: https://www.calculator.net/calorie-calculator.html
  const caloriesInFat = 9.0
  const caloriesInProtein = 4.0
  const caloriesInCarbs = 4.0
  // standard keto ratio
  const fatRatio = 0.7
  const proteinRatio = 0.25
  const carbsRatio = 0.05
  const goalModel = {}
  goalModel.calories = calories // calories.doubleValue >= 1200 ? calories : @(1200);
  goalModel.fat = (calories * fatRatio) / caloriesInFat
  goalModel.protein = (calories * proteinRatio) / caloriesInProtein
  goalModel.net_carbs = (calories * carbsRatio) / caloriesInCarbs
  return goalModel
}
