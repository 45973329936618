import Vue from 'vue'
import GtmMixin from '../components/GtmMixin'

Vue.mixin({
  mixins: [GtmMixin],
  methods: {
    clickGtmEvent(gtmEvent) {
      this.gtmPush({
        ...gtmEvent,
        event: 'button_clicked'
      })
    }
  }
})

Vue.directive('buttonGtm', {
  bind: (el, binding, vnode) => {
    el.clickListener = () => {
      vnode.context.clickGtmEvent(binding.value)
    }

    el.addEventListener('click', el.clickListener, {
      capture: true
    })
  },
  unbind(el) {
    el.removeEventListener('click', el.clickListener, {
      capture: true
    })
  }
})
