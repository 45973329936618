if (process.client) {
  // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search
  })

  // send active experiment into analytics
  window.dataLayer.push({
    event: 'optimize.callback',
    eventCallback() {
      if (window.gaData) {
        const running_experiments = {}
        let has_exp = false
        Object.values(window.gaData).forEach(value => {
          if (typeof value !== 'object') return
          if (!value || !value.experiments) return
          Object.entries(value.experiments).forEach(([key, value]) => {
            has_exp = true
            running_experiments[`experiment-${key}`] = value
          })
        })
        if (has_exp)
          window.dataLayer.push({
            event: 'experiments_initialized',
            running_experiments
          })
      }
    }
  })
}

export default ({ $config, store, route }) => {
  if (process.client) {
    // init build
    window.dataLayer.push({
      build: $config.build
    })

    // init experiment behaviour if exist
    window.dataLayer.push({
      event: 'optimize.callback',
      async eventCallback() {
        while (window.optimize && window.optimize.length)
          await store.dispatch('experiment/apply', window.optimize.pop())
        document.documentElement.style.removeProperty('opacity')
      }
    })
  }

  // shortcut to see experiments
  // EXAMPLE: http://localhost:3000/?experiments=[{"designs":["pricing-2021-01-14"],"plans":["recurly-3months","recurly-6months","recurly-1month"]}]
  // EXAMPLE: http://localhost:3000/?experiments=[{"designs":["no-password", "paypal"]}]
  // EXAMPLE: http://localhost:3000/?experiments=[{"screens":["ideal-weight-2020-12-30"]}]
  // EXAMPLE: http://localhost:3000/?experiments=[{"onboarding":"v2.0_introductory_v0.1","plans":["recurly-7days-ramp","recurly-1month-ramp","recurly-3months-ramp"]}]
  // EXAMPLE: http://localhost:3000/?experiments=[{"onboarding":"v2.1_introductory_v0.1"}]
  // EXAMPLE: http://localhost:3000/?experiments=[{"onboarding":"v2.0_introductory_v0.2"}]
  if (route.query?.experiments?.length) {
    console.log('gtm_start')
    try {
      const experiments = JSON.parse(route.query.experiments)
      while (experiments.length)
        store.dispatch('experiment/apply', experiments.pop())
    } catch (err) {
      console.error(err)
    }
  }
}
