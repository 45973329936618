export const state = () => ({
  show_privacy_policy: false,
  show_terms_conditions: false,
  show_money_back_policy: false
})

// getters
export const getters = {
  is_show_privacy_policy(state) {
    return state.show_privacy_policy
  },
  is_show_terms_conditions(state) {
    return state.show_terms_conditions
  },
  is_show_money_back_policy(state) {
    return state.show_money_back_policy
  }
}

export const actions = {
  async onPrivacyPolicy({ commit }, is_show) {
    commit('showPrivacyPolicy', is_show)
  },
  async onTermsConditions({ commit }, is_show) {
    commit('showTermsConditions', is_show)
  },
  async onMoneyBackPolicy({ commit }, is_show) {
    commit('showMoneyBackPolicy', is_show)
  }
}

export const mutations = {
  showPrivacyPolicy(state, is_show) {
    state.show_privacy_policy = is_show
  },
  showTermsConditions(state, is_show) {
    state.show_terms_conditions = is_show
  },
  showMoneyBackPolicy(state, is_show) {
    state.show_money_back_policy = is_show
  }
}
