import { MealplanBuilder } from '@/builders/mealplan-builder/mealplan-builder'
import { composedGoalKeto } from '@/helpers/composed_goal_keto'
import {
  MEALPLAN_PROCESS_TIP,
  RECIPE_ICON_MAP
} from '@/constants/meal_plan_offer'
import { format, differenceInCalendarDays } from 'date-fns'

export const state = () => ({
  building: false,
  mealplan: [],
  startDay: null,
  dayTips: MEALPLAN_PROCESS_TIP,
  mealplanEmail: null,
  isPaid: false,
  currentDay: null,
  mealplanId: null
})

export const getters = {
  firstDay({ mealplan }) {
    return mealplan?.[0] || null
  },
  mealplanId({ mealplanId }) {
    return mealplanId
  },
  mealplan({ mealplan }) {
    return mealplan
  },
  isFinished({ building }) {
    return !building
  },
  mealplanEmail({ mealplanEmail }) {
    return mealplanEmail
  },
  previewMealPlan({ mealplan }) {
    return mealplan.map(plan => ({
      ...plan,
      breakfast: {
        ...plan.breakfast,
        ...RECIPE_ICON_MAP.breakfast
      },
      dinner: {
        ...plan.dinner,
        ...RECIPE_ICON_MAP.dinner
      },
      lunch: {
        ...plan.lunch,
        ...RECIPE_ICON_MAP.lunch
      },
      snack: {
        ...plan.snack,
        ...RECIPE_ICON_MAP.snack
      }
    }))
  },
  firstDayPreviewMealPlan(state, { previewMealPlan }) {
    return previewMealPlan?.[0] || null
  },
  startDay({ startDay }) {
    return startDay
  },
  previewStartDay({ startDay }) {
    return Number(startDay)
  },
  hasStartDay({ startDay }) {
    return Boolean(startDay)
  },
  differentFromStartDay({ currentDay }, { hasStartDay, previewStartDay }) {
    return hasStartDay
      ? differenceInCalendarDays(currentDay, previewStartDay)
      : 0
  },
  dayTips({ dayTips }) {
    return dayTips
  },
  currentDayWithConfetti({ dayTips }, { differentFromStartDay }) {
    return dayTips[differentFromStartDay]?.withConfetti || false
  }
}

export const actions = {
  async build({ rootState, commit }) {
    commit('setBuilding', true)
    const recipes = (await import('assets/data/recipes.json')).default
    const form = rootState.user?.onboarding?.form || {}
    const mealplanBuilder = new MealplanBuilder()
    const mealplan = mealplanBuilder.generateMealPlan(
      recipes,
      form,
      composedGoalKeto(form)
    )
    commit('setMealPlan', mealplanBuilder.increaseMealplan(mealplan))
    commit('setBuilding', false)
  },
  async load({ commit }, mealplanId) {
    try {
      const fireDoc = await this.$fire.firestore
        .doc(`meal_plans/v0/users/${mealplanId}`)
        .get()
      const {
        mealplan,
        startDay = null,
        email = '',
        isPaid = false
      } = fireDoc.data()
      const formattedDay = startDay ? startDay.toDate() : null
      commit('setMealPlan', mealplan)
      commit('setStartDay', formattedDay)
      commit('setMealplanEmail', email)
      commit('setPaidStatus', isPaid)
      commit('setMealplanId', mealplanId)
    } catch (err) {
      console.log(err)
    }
  },
  async startJourneyDay({ commit, state }, mealplanId) {
    try {
      if (!state.isPaid) {
        return
      }

      const startDay = new Date()
      await this.$fire.firestore
        .doc(`meal_plans/v0/users/${mealplanId}`)
        .update({ startDay })

      commit('setStartDay', startDay)
    } catch (err) {
      console.log(err)
    }
  }
}

export const mutations = {
  setBuilding(state, building) {
    state.building = building
  },
  setMealPlan(state, mealplan) {
    state.mealplan = [...mealplan]
  },
  setStartDay(state, startDay) {
    state.startDay = startDay
    state.currentDay = Date.now()
  },
  setMealplanEmail(state, mealplanEmail) {
    state.mealplanEmail = mealplanEmail
  },
  setPaidStatus(state, isPaid) {
    state.isPaid = isPaid
  },
  setMealplanId(state, mealplanId) {
    state.mealplanId = mealplanId
  }
}
