export const state = () => ({
  userAgent: null
})

export const getters = {
  isMobileSafari({ userAgent }) {
    const ua = userAgent?.toLowerCase()
    const safari = /safari/.test(ua)
    const ios = /iphone|ipod|ipad/.test(ua)
    return ios && safari
  },
  isFacebook({ userAgent }) {
    const ua = userAgent?.toLowerCase()
    return /fban|fbav/.test(ua)
  },
  isInstagram({ userAgent }) {
    const ua = userAgent?.toLowerCase()
    return /instagram/.test(ua)
  }
}

export const actions = {
  async detectBrowser({ commit }, userAgent) {
    commit('setUserAgent', userAgent)
  }
}

export const mutations = {
  setUserAgent(state, userAgent) {
    state.userAgent = userAgent
  }
}
