import Vue from 'vue'

async function load(api_key) {
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://js.recurly.com/v4/recurly.js'

  const style = document.createElement('link')
  style.rel = 'stylesheet'
  style.href = 'https://js.recurly.com/v4/recurly.css'

  const firstScript = document.getElementsByTagName('script')[0]
  await Promise.all([
    new Promise((resolve, reject) => {
      script.onload = resolve
      script.onerror = reject
      firstScript.parentNode.insertBefore(script, firstScript)
    }),
    new Promise((resolve, reject) => {
      style.onload = resolve
      style.onerror = reject
      document.head.appendChild(style)
    })
  ])
  window.recurly.configure(api_key)
  return window.recurly
}

const VueLazyRecurly = {
  install(_Vue, { api_key }) {
    Object.defineProperty(_Vue.prototype, '$recurly', {
      enumerable: true,
      get: () => window.recurly
    })

    let loading = null
    Object.defineProperty(_Vue.prototype, '$recurlyLoad', {
      enumerable: true,
      get: () => async () => {
        if (window.recurly) return window.recurly
        if (loading) return loading
        try {
          loading = load(api_key)
          return await loading
        } catch (err) {
          console.error(err)
          throw err
        } finally {
          loading = null
        }
      }
    })
  }
}

export default ({ $config }) =>
  Vue.use(VueLazyRecurly, {
    api_key: $config.recurly_api_key
  })
