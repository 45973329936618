import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email, min, max_value } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)
extend('min', min)
extend('max_value', max_value)
extend('between', {
  params: ['min', 'max', 'unit'],
  validate(value, { min, max, unit = '' }) {
    min = Number(min)
    max = Number(max)
    if (typeof value !== 'number' || isNaN(value))
      return '{_field_} is not valid'
    if (typeof min === 'number' && value < min)
      return `{_field_} should be more than ${min} ${unit}`
    if (typeof max === 'number' && value > max)
      return `{_field_} should be less than ${max} ${unit}`
    return true
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})
