import { EXPERIMENTS } from '@/enums'

export const state = () => ({
  designs: [EXPERIMENTS.DESIGN.NO_PASSWORD],
  screens: []
})

export const actions = {
  async apply({ state, commit, dispatch }, expData) {
    console.log(expData)
    if (expData?.plans?.length)
      await dispatch(
        'plan/defaultPlanIds',
        {
          planIds: [...expData.plans]
        },
        { root: true }
      )
    if (expData?.designs?.length) commit('setDesigns', [...expData.designs])
    if (expData?.screens?.length) commit('setScreens', [...expData.screens])
    if (expData?.onboarding)
      await dispatch('onboarding/setVersion', expData.onboarding, {
        root: true
      })
    if (expData?.paymentGates?.length)
      commit('plan/setDefaultPaymentGates', expData.paymentGates, {
        root: true
      })
  }
}

export const mutations = {
  setDesigns(state, designs) {
    state.designs = [...designs]
  },
  setScreens(state, screens) {
    state.screens = [...screens]
  }
}
