<script>
export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      title: this.message
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      if (this.statusCode === 404) return 404
      return this.error.message || 'Unknown error happened'
    }
  }
}
</script>

<template>
  <section class="hero is-medium my-6">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1
          class="title is-size-1"
          :class="statusCode === 404 ? 'has-text-centered' : null"
        >
          {{ message }}
        </h1>
      </div>
    </div>
  </section>
</template>
