import Vue from 'vue'

let loading = false
async function load(appId) {
  try {
    loading = true
    const script = document.createElement('script')
    script.async = true
    script.src = `https://widget.intercom.io/widget/${appId}`
    const firstScript = document.getElementsByTagName('script')[0]
    await new Promise((resolve, reject) => {
      script.onload = resolve
      script.onerror = reject
      firstScript.parentNode.insertBefore(script, firstScript)
    })
  } catch (err) {
    console.error(err)
  } finally {
    loading = false
  }
}

const VueLazyIntercom = {
  install(_Vue, options) {
    if (window.Intercom) return
    window.intercomSettings = options

    const Intercom = function () {
      Intercom.c(arguments)
    }
    Intercom.q = []
    Intercom.c = function (args) {
      if (!loading) load(options.app_id) // lazy load intercom, after loading window.Intercom is replaced
      Intercom.q.push(args)
    }

    window.Intercom = Intercom

    Object.defineProperty(_Vue.prototype, '$intercom', {
      enumerable: true,
      get: () => window.Intercom
    })
  }
}

export default ({ $config }) =>
  Vue.use(VueLazyIntercom, {
    app_id: $config.intercom_app_id,
    hide_default_launcher: true
  })
