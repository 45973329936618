import { isInRatio } from '@/helpers/is_in_ratio'
import { TAGS_MAPPING } from '@/builders/mealplan-builder/tags_mapping'
import { MEALPLAN_MEAT } from '@/enums'
import { removeFromArr } from '@/helpers/remove_from_array'
import { getRandomInt } from '@/helpers/get-random-int'

export class MealplanBuilder {
  constructor() {
    this.defaultTags = TAGS_MAPPING
    this.clearTags()
  }

  addTagsToIncludeCollectionByCategory(categoryName) {
    this.includeTags.push(...this.defaultTags[categoryName])
  }

  addTagsToExcludeCollectionByCategory(categoryName) {
    this.excludeTags.push(...this.defaultTags[categoryName])
  }

  addTagsToExcludeCollectionByCategories(categories) {
    this.excludeTags.push(
      ...categories.flatMap(categoryName => this.defaultTags[categoryName])
    )
  }

  clearTags() {
    this.includeTags = []
    this.excludeTags = []
  }

  analyzeFormTags(form) {
    this.clearTags()

    if (form.preparation_time) {
      this.addTagsToIncludeCollectionByCategory(form.preparation_time)
    }

    if (form.meat_preference?.length) {
      if (form.meat_preference.includes(MEALPLAN_MEAT.VEGETARIAN)) {
        this.addTagsToIncludeCollectionByCategory(MEALPLAN_MEAT.VEGETARIAN)
        this.addTagsToExcludeCollectionByCategories([
          MEALPLAN_MEAT.CHICKEN,
          MEALPLAN_MEAT.PORK,
          MEALPLAN_MEAT.BACON,
          MEALPLAN_MEAT.BEEF,
          MEALPLAN_MEAT.FISH,
          MEALPLAN_MEAT.LAMB,
          MEALPLAN_MEAT.TURKEY
        ])
      } else {
        form.meat_preference.forEach(meat =>
          this.addTagsToIncludeCollectionByCategory(meat)
        )
      }
    }

    if (form.food_preference?.length) {
      form.food_preference.forEach(food =>
        this.addTagsToIncludeCollectionByCategory(food)
      )
    }

    if (form.allergies?.length) {
      form.allergies.forEach(allergy =>
        this.addTagsToExcludeCollectionByCategory(allergy)
      )
    }
  }

  groupRecipes(recipes) {
    return recipes
      .filter(({ TAGS }) => !TAGS.some(t => this.excludeTags.includes(t)))
      .map(r => {
        const rating = r.TAGS.reduce(
          (rt, t) => rt + this.includeTags.includes(t),
          0
        )
        return {
          id: r.id,
          name: r.name,
          category: r.category === 'lunch' ? 'dinner' : r.category,
          net_carbs: r.net_carbs || r.carbohydrate - r.fiber,
          fat: r.fat,
          protein: r.protein,
          calories: r.calories,
          tags: r.TAGS,
          totalTime: r.totalTime,
          ingredients: r.ingredients,
          instructions: r.instructions,
          images: r.images,
          rating
        }
      })
      .sort((r1, r2) => r2.rating - r1.rating)
      .reduce((acc, r) => {
        if (!acc[r.category]) {
          acc[r.category] = []
        }
        acc[r.category].push(r)
        return acc
      }, {})
  }

  buildDayPlan(
    { breakfast, dinner, snack }, // groups
    target
  ) {
    let bestOpt = []
    let bestScore = Number.POSITIVE_INFINITY
    let bestStats = {
      calories: 0,
      net_carbs: 0,
      protein: 0,
      fat: 0
    }
    const opt = {
      calories: 0,
      net_carbs: 0,
      protein: 0,
      fat: 0
    }
    const ratio = target.deviation / 100

    for (let bIdx = 0; bIdx < breakfast.length; bIdx++) {
      const b = breakfast[bIdx]
      opt.calories += b.calories
      opt.net_carbs += b.net_carbs
      opt.protein += b.protein
      opt.fat += b.fat

      for (let lIdx = 0; lIdx < dinner.length; lIdx++) {
        const l = dinner[lIdx]
        opt.calories += l.calories
        opt.net_carbs += l.net_carbs
        opt.protein += l.protein
        opt.fat += l.fat

        for (let dIdx = 0; dIdx < dinner.length; dIdx++) {
          if (dIdx === lIdx) continue // force not same dinner and launch

          const d = dinner[dIdx]
          opt.calories += d.calories
          opt.net_carbs += d.net_carbs
          opt.protein += d.protein
          opt.fat += d.fat

          for (let sIdx = 0; sIdx < snack.length; sIdx++) {
            const s = snack[sIdx]
            opt.calories += s.calories
            opt.net_carbs += s.net_carbs
            opt.protein += s.protein
            opt.fat += s.fat

            const calories_ok = isInRatio(opt.calories, target.calories, ratio)
            const net_carbs_ok = isInRatio(
              opt.net_carbs,
              target.net_carbs,
              ratio
            )
            const protein_ok = isInRatio(opt.protein, target.protein, ratio)
            const fat_ok = isInRatio(opt.fat, target.fat, ratio)

            if (calories_ok && net_carbs_ok && protein_ok && fat_ok)
              return {
                plan: [b, l, d, s],
                stats: {
                  ...opt
                },
                failed: false
              }

            const optScore =
              Math.abs(opt.calories - target.calories) / 15 +
              Math.abs(opt.net_carbs - target.net_carbs) +
              Math.abs(opt.protein - target.protein) +
              Math.abs(opt.fat - target.fat)

            if (optScore < bestScore) {
              bestScore = optScore
              bestOpt = [b, l, d, s]
              bestStats = {
                ...opt
              }
            }

            opt.calories -= s.calories
            opt.net_carbs -= s.net_carbs
            opt.protein -= s.protein
            opt.fat -= s.fat
          }

          opt.calories -= d.calories
          opt.net_carbs -= d.net_carbs
          opt.protein -= d.protein
          opt.fat -= d.fat
        }

        opt.calories -= l.calories
        opt.net_carbs -= l.net_carbs
        opt.protein -= l.protein
        opt.fat -= l.fat
      }

      opt.calories = 0
      opt.net_carbs = 0
      opt.protein = 0
      opt.fat = 0
    }

    return {
      plan: bestOpt,
      stats: bestStats,
      failed: true
    }
  }

  generateMealPlan(
    recipes,
    form,
    target = {
      deviation: 10,
      calories: 1500,
      net_carbs: 20,
      protein: 94,
      fat: 116
    }
  ) {
    this.analyzeFormTags(form)
    const groupedRecipes = this.groupRecipes(recipes)

    const allBreakfasts = [...groupedRecipes.breakfast]
    const allDinners = [...groupedRecipes.dinner]
    const allSnacks = [...groupedRecipes.snacks]

    return Array.from({ length: 14 }).map((_, index) => {
      const options = 20
      const { plan, stats, failed } = this.buildDayPlan(
        {
          breakfast: allBreakfasts.slice(0, options),
          dinner: allDinners.slice(0, options * 2),
          snack: allSnacks.slice(0, options)
        },
        {
          ...target
        }
      )

      const [breakfast, lunch, dinner, snack] = plan

      removeFromArr(allBreakfasts, breakfast)
      removeFromArr(allDinners, lunch)
      removeFromArr(allDinners, dinner)
      removeFromArr(allSnacks, snack)

      return {
        failed,
        breakfast,
        lunch,
        dinner,
        snack,
        day: index + 1,
        total: stats
      }
    })
  }

  increaseMealplan(mealplan, maxLength = 14) {
    const usedMealPlan = new Set()
    const allMealPlan = [...mealplan]
    const { length } = mealplan

    for (let i = 0; i < maxLength; i++) {
      const breakfast = this.getRandomMealplanByType(
        mealplan,
        'breakfast',
        usedMealPlan
      )
      const lunch = this.getRandomMealplanByType(
        mealplan,
        'lunch',
        usedMealPlan
      )
      const dinner = this.getRandomMealplanByType(
        mealplan,
        'dinner',
        usedMealPlan
      )
      const snack = this.getRandomMealplanByType(
        mealplan,
        'snack',
        usedMealPlan
      )
      const mealplanDay = [breakfast, lunch, dinner, snack]
      const newDay = {
        failed: false,
        breakfast,
        lunch,
        dinner,
        snack,
        day: length + i + 1,
        total: {
          calories: this.calculateTotal(mealplanDay, 'calories'),
          net_carbs: this.calculateTotal(mealplanDay, 'net_carbs'),
          protein: this.calculateTotal(mealplanDay, 'protein'),
          fat: this.calculateTotal(mealplanDay, 'fat')
        }
      }
      allMealPlan.push(newDay)
    }

    return allMealPlan
  }

  getRandomMealplanByType(mealplan, type, usedMealPlan) {
    const plan = mealplan[getRandomInt(0, mealplan.length - 1)][type]

    if (usedMealPlan.has(`${plan.name} ${type}`)) {
      return this.getRandomMealplanByType(mealplan, type, usedMealPlan)
    }

    usedMealPlan.add(`${plan.name} ${type}`)
    return plan
  }

  calculateTotal(mealplan, type) {
    return mealplan.reduce((acc, item) => acc + item[type], 0)
  }
}
