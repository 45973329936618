export function caloriesIntakeForUserParameters(userParameters) {
  // For men: BMR = 10W + 6.25H - 5A + 5
  // For women: BMR = 10W + 6.25H - 5A - 161
  const age = userParameters.age
  let caloriesIntake =
    10.0 * userParameters.weight + 6.25 * userParameters.height - 5.0 * age
  // BMR
  caloriesIntake =
    caloriesIntake + (userParameters.gender === 'male' ? 5 : -161)
  // Real
  caloriesIntake = caloriesIntake * userParameters.activity_level
  // Substitute 250 calories each day for slightly weight loss
  // in order to lose 1 pound per week, it is recommended that 500 calories be shaved off
  // let's start with 0.5 pound per week for now
  // const reduceValue = ANServiceProviderI.productSettingsService.productSettings.calculatorSettings.reduceCaloriesValue;
  const reduceValue = 250
  if (!userParameters.weight_loss_speed) {
    caloriesIntake = caloriesIntake - (reduceValue ? reduceValue : 0.0)
  } else {
    caloriesIntake = caloriesIntake * (userParameters.weight_loss_speed || 1)
  }
  if (caloriesIntake < 1200.0) {
    caloriesIntake = 1200.0
  }

  return caloriesIntake
}
