<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PrivacyPolicy',
  computed: {
    ...mapGetters('popups', ['is_show_privacy_policy'])
  },
  methods: {
    closePrivacyPolicy() {
      this.$store.dispatch('popups/onPrivacyPolicy', false)
    }
  }
}
</script>

<template>
  <div
    class="modal has-text-left"
    :class="{
      'is-active': is_show_privacy_policy
    }"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box px-2 py-2">
        <button
          v-button-gtm="{
            title: 'Close Privacy Policy',
            screen: $options.category
          }"
          class="delete ml-2 mt-2"
          aria-label="close"
          @click="closePrivacyPolicy"
        />
        <div class="box__text pt-4 px-2">
          Effective October 1, 2018 <br /><br />
          We are committed to respecting your data privacy and providing
          transparency about our data practices. This Privacy Policy (this
          "Privacy Policy") explains how MyKeto ("Company" or "we" or "us")
          collects, stores, uses, transfers and discloses Personal Data from our
          users ("you") in connection with the Company mobile application and
          related services (collectively, the "App"). <br /><br />
          We reserve the right to and may change this Privacy Policy from time
          to time. If we make any material changes, we will notify you by email
          (sent to the email address specified when you register), through the
          App, or by presenting you with a new version of this Privacy Policy
          for you to accept, if we, for example, add new processing activities
          or collect some additional Personal Data from you. <br /><br />
          Your continued use of the App after the effective date of an updated
          version of the Privacy Policy will indicate your acceptance of the
          Privacy Policy as modified. In some cases, you will have to explicitly
          accept changes to Privacy Policy to continue using the App. We
          encourage you to periodically review our website for the latest
          information on our data privacy practices. If you do not accept the
          terms of the Privacy Policy, we ask that you do not use the App.
          Please exit the App immediately and delete it if you do not agree to
          the terms of this Privacy Policy.
          <br /><br />
          1. PERSONAL DATA AND INFORMATION WE COLLECT ABOUT YOU
          <br /><br /><strong
            >We collect the following Personal Data and information from
            you:</strong
          >
          <ul>
            <li>
              Contact Information such as name, email address, mailing address,
              phone number.
            </li>
            <li>
              Preferences Information you provide such as product or content
              interests, or communication or marketing preferences.
            </li>
            <li>
              Usage activity about how you interact with us such as purchase
              history, what content you viewed, and which areas of our site you
              visited.
            </li>
            <li>
              Fitness, Health or Medical information such as prescription
              information, your weight, height, different symptoms, nutrition,
              consumption of water.
            </li>
            <li>
              Device and Usage Information that may include information specific
              to your mobile device (e.g., make, model, operating system,
              advertising identifier and similar information); information about
              your use of features, functions, or notifications on the device;
              and signal strength relating to WiFi or Bluetooth functionality,
              temperature, battery level, and similar technical data.
            </li>
            <li>
              Location Information that allows us to periodically determine your
              location, including your location relative to and within third
              party merchant locations
            </li>
            <li>Demographic information such as age and gender</li>
            <li>
              Information about your mobile device such as your mobile device id
              (similar to a website IP address), device type, and mobile service
              carrier.
            </li>
          </ul>
          We may collect this Personal data and information even if you are not
          logged in to the application. For iOS users: You should be able to
          adjust the settings on your iOS mobile device to prevent our
          collection of Location Information by disabling the location services
          feature on your device. For Android users: You should be able to
          adjust your settings on your Android mobile device to prevent our
          collection of Location Information be disabling Bluetooth and
          Location. <br /><br />
          You will also have an option to permit us to import and export the App
          data from third-party services such as Apple HealthKit, among others.
          Such information may include: sports activities, weight, calories
          burnt, heartbeat rate, number of steps/distance travelled, and other
          information about your health. <br /><br />
          <ul>
            <li>
              We do not use the information obtained via the HealthKit framework
              for advertising or similar purposes.
            </li>
            <li>
              We do not disclose any information obtained via HealthKit to third
              parties without expressed permission from the users.
            </li>
            <li>
              We do not sell information obtained via HealthKit to advertising
              platforms, data brokers or information resellers.
            </li>
          </ul>
          Permitting us to access third party information can help you to
          maximize your App experience, and we will handle any such third-party
          information in full accordance with this Privacy Policy. <br /><br />
          If the information covered by this Section is aggregated or
          de-identified so it is no longer reasonably associated with an
          identified or identifiable natural person, we may use it for any
          business purpose. To the extent information covered by this Section is
          associated with an identified or identifiable natural person and is
          protected as personal data under applicable data protection laws, it
          is referred to in this Privacy Policy as
          <strong>"Personal Data"</strong>. We use pseudonymization for
          particular types of Personal Data. Please bear in mind that provisions
          of Section 3 do not apply to pseudonymized Personal Data. <br /><br />
          <strong>YOUR CONSENT FOR PROCESSING OF YOUR PERSONAL DATA.</strong> By
          installing the App and creating a profile in the App, (or by
          explicitly accepting this Privacy Policy and explicitly consenting to
          the processing of your Personal Data) you explicitly consent that:
          <br /><br />
          <ul>
            <li>
              WE MAY STORE AND PROCESS YOUR PERSONAL DATA YOU PROVIDE THROUGH
              THE USAGE OF THE APP AND THROUGH THE ACCOUNT CREATION PROCESS
              SOLELY FOR THE PURPOSE OF PROVIDING SERVICES TO YOU, TO IMPROVE
              OUR SERVICE FEATURES AND OTHER PURPOSES INDICATED IN SECTION 2 OF
              THIS PRIVACY POLICY. SUCH SERVICES MAY INCLUDE SENDING YOU
              INFORMATION AND REMINDERS THROUGH THE APP OR TO THE EMAIL ADDRESS
              YOU PROVIDED TO US.
            </li>
            <li>
              PERSONAL DATA YOU PROVIDE TO US THROUGH THE ACCOUNT CREATION
              PROCESS OR USE OF THE APP INCLUDES PERSONAL DATA YOU ENTER INTO
              THE APP, SUCH AS YOUR ACCOUNT DATA (E.G. YOUR NAME AND EMAIL
              ADDRESS), AND YOUR HEALTH DATA (E.G. BODY MEASUREMENTS, PHYSICAL
              ACTIVITY AND OTHERS). DEPENDING ON THE DATA YOU PROVIDE, IT MAY
              ALSO CONTAIN INFORMATION ABOUT YOUR GENERAL HEALTH (E.G. WEIGHT,
              BODY TEMPERATURE, AND OTHERS).
            </li>
            <li>
              WE WILL NOT TRANSMIT ANY OF YOUR PERSONAL DATA TO THIRD PARTIES,
              EXCEPT IF IT IS REQUIRED TO PROVIDE THE SERVICE TO YOU (E.G.
              TECHNICAL SERVICE PROVIDERS), UNLESS WE HAVE ASKED FOR YOUR
              EXPLICIT CONSENT.
            </li>
          </ul>
          2. HOW WE USE YOUR PERSONAL DATA AND INFORMATON <br /><br />
          We may use your information, including your Personal Data, and by
          accepting this Privacy Policy you provide us your explicit consent,
          for the following purposes: <br /><br />
          <ul>
            <li>
              to analyze, operate, maintain and improve the App and marketing
              activities, to add new features and services to the App;
            </li>
            <li>
              to assess your needs to determine suitable products or services;
            </li>
            <li>to send product updates or warranty information;</li>
            <li>
              to respond to your comments, questions and requests and provide
              customer service;
            </li>
            <li>
              to send you push notifications, both internal and external. IF YOU
              DO NOT WANT TO RECEIVE THESE NOTIFICATIONS, YOU CAN MANAGE YOUR
              PREFERENCE EITHER THROUGH YOUR DEVICE OR APP SETTINGS DEPENDING ON
              YOUR DEVICE TYPE. YOU CAN ALSO CONTACT US AT
              <a href="mailto:support@myketo.care">support@myketo.care</a>
            </li>
            <li>to send you marketing communications;</li>
            <li>
              to improve our App and marketing efforts.to conduct research and
              analysis, including for scientific and academic research purposes;
              and
            </li>
            <li>
              for any other purposes disclosed to you at the time we collect
              Personal Data or any other purposes indicated in this Privacy
              Policy.
            </li>
          </ul>
          <strong>Choice/Opt-Out.</strong> You may opt-out of receiving our
          newsletter or marketing emails from us by following the unsubscribe
          instructions included in each newsletter or marketing email or you can
          contact us at
          <a href="mailto:support@myketo.care">support@myketo.care</a>.
          <br /><br />
          <strong>Social Networks.</strong> We enable you to create a profile,
          and share information such as messages, photos, and videos with others
          within your network. We cannot control the actions of others with whom
          you may choose to share your pages and information. Also, we cannot
          guarantee that the content you post on our App will not be viewed by
          others outside your network. <br /><br />
          Any information (including Personal Data) you share in any online
          community area or online discussion is by design open to the public
          and is not private, unless provided differently in the App settings.
          You should think carefully before posting any Personal Data in any
          public forum. What you post can be seen, disclosed to, or collected by
          third parties and may be used by others in ways we cannot control or
          predict, including to contact you for unauthorized purposes. As with
          any public forum on any site, the information you post may also show
          up in third-party search engines. <br /><br />
          If you mistakenly post Personal Data in our community areas and would
          like it removed, you can send us an email as listed below to request
          that we remove it. In some cases, we may not be able to remove your
          Personal Data, e.g. for technical reasons. <br /><br />
          <strong>Information Obtained from Third Parties.</strong> Unless it is
          directly prohibited under applicable law in certain jurisdiction, we
          purchase marketing data about our customers from third parties and
          combine it with Personal Data we already have about you, to create
          more tailored advertising and products. Such data never includes any
          of your personal data that is considered to be 'sensitive' or falls
          under category of 'special categories of personal data' under
          applicable law. We will only use that information for the specific
          reason for which it was provided to us. By accepting this Privacy
          Policy, you provide your consent for such processing activities.
          <br /><br />
          3. YOUR RIGHTS <br /><br />
          <strong>Modification, correction, and erasure.</strong> You are able
          to modify, correct, erase and update your Personal Data in the App
          account settings or, if that is impossible, by writing us at
          <a href="mailto:support@myketo.care">support@myketo.care</a>.
          <br /><br />
          <strong>Access.</strong> You have a right to access your Personal Data
          you insert into the App and ask us about what king of Personal Data we
          have about you. You can do this by using the app settings or by
          writing to
          <a href="mailto:support@myketo.care">support@myketo.care</a>.
          <br /><br />
          <strong>EU residents.</strong> Individuals residing in the countries
          of the European Union have certain statutory rights in relation to
          their personal data introduced by the General Data Protection
          Regulation (the "GDPR"). Subject to any exemptions provided by law,
          you may have the right to request access to Personal data (including
          in a structured and portable form), as well as to seek to update,
          delete or correct Personal data: <br /><br />
          <ul>
            <li>
              <strong
                >Rectification of Personal Data and Restriction of
                Processing.</strong
              >
              You are responsible for ensuring the accuracy of your Personal
              Data that you submit to the App. Inaccurate information will
              affect your experience when using the App Web Sites and tools and
              our ability to contact you as described in this Privacy Policy. If
              you believe that your Personal Data is inaccurate, you have right
              to contact us and ask us to correct such Personal Data by
              contacting us at
              <a href="mailto:support@myketo.care">support@myketo.care</a>. You
              shall also have the right to request restriction of processing of
              your Personal Data, if you contest the accuracy of the Personal
              Data and we need some time to verify its accuracy.
            </li>
            <li>
              <strong
                >Access to your Personal Data and Data Portability.</strong
              >
              The App gives you the ability to access and update Personal Data
              within the App and your account settings. You shall have the right
              to request information about whether we have any Personal Data
              about you, to access your Personal data (including in a structured
              and portable form) by using your app settings or by simply writing
              us at
              <a href="mailto:support@myketo.care">support@myketo.care</a>.
            </li>
            <li>
              <strong>Erasure of your Personal Data.</strong> If you believe
              that your Personal Data is no longer necessary in relation to the
              purposes for which it was collected or otherwise processed, or in
              cases where you have withdrawn your consent or object to the
              processing of your Personal Data, or in cases where the processing
              of your Personal Data does not otherwise comply with the GDPR, you
              have right to contact us and ask us to erase such Personal Data as
              described above. You can use simply write us at
              <a href="mailto:support@myketo.care">support@myketo.care</a>.
              Please be aware that erasing some Personal Data inserted by you
              may affect your possibility to use the App and its features.
              Erasure of some Personal Data may also take some time due to
              technical reasons.
            </li>
            <li>
              <strong>Right to object processing of your Personal Data.</strong>
              You can object processing your Personal Data and stop us from
              processing your Personal data you can simply write us at
              <a href="mailto:support@myketo.care">support@myketo.care</a>.
              Please be aware that erasing some Personal Data inserted by you
              may affect your possibility to utilize the App and its features.
            </li>
            <li>
              <strong>Notification requirements.</strong> We commit to notify
              you within reasonable period of time and your data protection
              authority within the timeframe specified in applicable law (72
              hours) about any personal data breaches in the App.
            </li>
            <li>
              <strong>Data Protection Authorities.</strong>Subject to GDPR, you
              also have the right to (i) restrict our use of Personal Data and
              (ii) lodge a complaint with your local data protection authority
              about any of our activities that you deem are not compliant with
              GDPR.
            </li>
          </ul>
          Please keep in mind that in case of a vague access, erasure, objection
          request or any other request in exercise of the mentioned rights we
          may engage the individual in a dialogue so as to better understand the
          motivation for the request and to locate responsive information. In
          case this is impossible, we reserve the right to refuse granting your
          request.
          <br /><br />
          Following the provisions of GDPR we might also require you to prove
          your identity (for example, by requesting an ID or any other proof of
          identity) in order for you to invoke the mentioned rights,
          specifically if you exercise them in respect to special categories of
          Personal Data like data about health. This is made to ensure that no
          rights of third parties are violated by your request, and the rights
          described in this section are exercised by an actual Personal Data
          subject or an authorized person.
          <br /><br />
          4. SHARING YOUR PERSONAL DATA AND INFORMATION <br /><br />
          <strong>Sharing with third parties.</strong> We will share your
          Personal Data and information with third parties only in the ways that
          are described in this Privacy Policy. <br /><br />
          <strong>We may disclose your Personal Data:</strong> <br /><br />
          <ul>
            <li>
              as required by law, such as to comply with a subpoena, or similar
              legal process.
            </li>
            <li>
              when we believe in good faith that disclosure is necessary to
              protect our rights, protect your safety or the safety of others,
              investigate fraud, or respond to a government request.
            </li>
            <li>
              if we are involved in a merger, acquisition, or sale of all or a
              portion of its assets, you will be notified via email and/or a
              prominent notice on our Web site of any change in ownership or
              uses of your personal information, as well as any choices you may
              have regarding your personal information.
            </li>
            <li>
              to any other third party with your prior explicit consent to do
              so.
            </li>
          </ul>
          We share the information we collect with third-party business partners
          who commit to comply with applicable laws concerning their usage of
          your Personal Data (for example, GDPR for the EU residents). Our
          third-party business partners may share your Personal Data with their
          own third-party business partners, granted they have appropriate data
          protection safeguards in place, and use the information either for
          themselves or on behalf of their own business partners to:
          <br /><br />
          <ul>
            <li>Operate and improve their services;</li>
            <li>
              Provide you with advertisements and information for products and
              services in this app or on other apps or websites based on
              variables such as your location, and assess your level of
              engagement with them; and
            </li>
            <li>
              Carry out other purposes that are disclosed to you and to which
              you consent.
            </li>
          </ul>
          Depending on a service you use, we may share some of your Personal
          Data and information with third-party business partners who perform
          analytical and other tasks for us (in some jurisdictions such parties
          may qualify for being a 'processor' under applicable laws). These are
          the third-party services with whom, among others, we may share some of
          your Personal Data and information: <br /><br />
          <ol>
            <li>
              <strong>Appsflyer.</strong> Appsflyer is a mobile marketing
              platform. We may share certain non-identifiable information about
              you and some Personal Data (but never any data related to health)
              in order to carry out marketing activities and provide you better
              and more targeted service. Learn more about Appsflyer:
              <a href="https://www.appsflyer.com/">https://www.appsflyer.com</a>
            </li>
            <li>
              <strong>MoPub.</strong> Mopub is a subsidiary of Twitter, Inc. and
              a monetization platform. We may share non-identifiable information
              about you and some Personal Data (but never any data related to
              health) in order to carry out marketing and adds activities. Learn
              more about MoPub:
              <a href="https://www.mopub.com/">https://www.mopub.com</a>
            </li>
            <li>
              <strong>Facebook.</strong> We use Facebook as our advertising
              partner to display advertising in our App or to manage our
              advertising on other sites. Normally, Facebook collects only
              non-personally identifiable information about your activities on
              this App and other apps to provide you targeted advertising based
              upon your interests. Learn more about Facebook advertising policy:
              <a href="https://m.facebook.com/ads/ad_choices"
                >https://m.facebook.com/ads/ad_choices</a
              >.
            </li>
            <li>
              <strong>Fabric.</strong> We use Fabric, an analytics company and a
              Google subsidiary, to better understand your use of the App. For
              example, Fabric may use device identifiers that are stored on your
              mobile device and allow us to analyze your use of the App in order
              to improve our App features. Read more about Fabric here:
              <a href="https://get.fabric.io/">https://get.fabric.io</a>
            </li>
            <li>
              <strong>Crashlytics.</strong>Crashlytics, a Google business
              division, provides us crash reporting solution that we use in
              order to track when the App crashes or works improperly on your
              device. Crashlytics may collect certain device identifiers in
              order to understand on what devices and under which circumstances
              the App does not work or crashes. See more about Crashlytics here:
              <a href="https://try.crashlytics.com/reports/"
                >https://try.crashlytics.com/reports/</a
              >
            </li>
            <li>
              <strong>Appsee.</strong> Appsee is s a mobile app analytics
              platform that provides us tools that enable us to track and
              optimize the UX in the App through the use of some device
              identifiers. See more here:
              <a href="https://www.appsee.com/">https://www.appsee.com</a>
            </li>
            <li>
              <strong>Flurry.</strong>Flurry is a Yahoo! Subsidiary and
              analytical platform that we use in order to analyze different use
              trends in our App. We may share certain non-identifiable
              information about you and some Personal Data (but never any data
              related to health) with Flurry. See more:
              <a
                href="https://developer.yahoo.com/flurry/legal-privacy/tos.html"
                >https://developer.yahoo.com/flurry/legal-privacy/tos.html</a
              >
            </li>
            <li>
              <strong>Zendesk.</strong>We use Zendesk as an intermediary for
              user support services. Zendesk may collect some of your Personal
              data (like contact information) in order to connect us with you.
              See more about Zendesk and its privacy policy:
              <a
                href="https://www.zendesk.com/company/customers-partners/eu-data-protection/"
                >https://www.zendesk.com/company/customers-partners/eu-data-protection/</a
              >
            </li>
            <li>
              <strong>Twitter.</strong>We use Twitter as our advertising partner
              to display advertising in Twitter. Normally, Twitter collects only
              non-personally identifiable information about your activities on
              this App and other apps to provide you targeted advertising based
              upon your interests. See more about Twitter's privacy approach:<a
                href="https://help.twitter.com/en/rules-and-policies/update-privacy-policy"
                >https://help.twitter.com/en/rules-and-policies/update-privacy-policy</a
              >
            </li>
            <li>
              <strong>Firebase.</strong>Firebase is an analytics product from
              Google, Inc. that allows us to track crashes of the App, monitor
              events in the App, provide us stats regarding the use of the App.
              Firebase may collect certain device identifiers in order to
              understand on what devices and under which circumstances
              particular incidents and events happen. See more on Firebase:
              <a href="https://firebase.google.com/"
                >https://firebase.google.com/</a
              >
            </li>
          </ol>
          Our third-party partners are either EU-based or certified under EU-US
          Privacy Shield Framework that ensures that European data protection
          requirement are met. The privacy policy of these services can be found
          on their respective websites. Be informed that some third-party
          service providers may have their servers located in the United States.
          <br /><br />
          BY USING THE APP, YOU CONSENT THAT WE MAY USE COOKIES AND THIRD-PARTY
          SERVICES AND COLLECT YOUR USAGE (INCLUDING FOR PROCESSING BY
          THIRD-PARTY SERVICE PROVIDERS) AND SOME PERSONAL DATA UNDER A UNIQUE
          IDENTIFIER, FOR THE PURPOSES OF TRACKING, ANALYSIS, AND IMPROVEMENT OF
          THE APP. BY USING OUR SERVICE, YOU EXPLICITLY CONSENT TO THE USE AND
          PROCESSING OF YOUR DATA COLLECTED AS DESCRIBED ABOVE. <br /><br />
          <strong>Aggregated Information.</strong> We may also share aggregated,
          anonymized or de-identified information, which cannot reasonably be
          used to identify you. For example, we may share, including, without
          limitation, in articles, blog posts and scientific publications,
          general age demographic information and aggregate statistics about
          certain activities or symptoms from data collected to help identify
          patterns across users. <br /><br />
          5. DATA SECURITY <br /><br />
          Security of your Personal Data is important to us. When you provide
          your Personal data that is considered to be sensitive or falls under
          "categories of special data" under applicable laws to us, we encrypt
          the transmission of that information using secure socket layer
          technology (SSL). We follow generally accepted industry standards to
          protect the Personal Data submitted to us, both during transmission
          and once we receive it. <br /><br />
          Among others, we utilize the following security measures to protect
          your Personal Data: <br /><br />
          <ul>
            <li>
              Pseudominization and tokenization of certain categories of your
              Personal Data;
            </li>
            <li>Protection of data integrity;</li>
            <li>Encryption of your Personal Data in transit and in rest;</li>
            <li>Systematic vulnerability scanning and penetration testing;</li>
            <li>
              Organizational and legal measures. For example, our employees have
              different levels of access to your Personal Data, and only those
              in charge of data management get access to your Personal Data and
              only for limited purposes required for the operation of the App.
              We impose strict liability on our employees for any disclosures,
              unauthorized accesses, alterations, destructions, misuses of your
              Personal Data.
            </li>
            <li>
              Conducting periodical data protection impact assessments in order
              to ensure that the App fully adheres to the principles of 'privacy
              by design', 'privacy by default' and other internationally
              accepted data protection principles. We also commit to undertake
              privacy audit in case of Company's merger or takeover.
            </li>
          </ul>
          Bear in mind that no method of transmission, or method of electronic
          storage, is 100% secure. Therefore, we cannot guarantee its absolute
          security. If you have any questions about security on our App, you can
          contact us at
          <a href="mailto:support@myketo.care">support@myketo.care</a>.
          <br /><br />
          6. RETENTION AND STORAGE OF YOUR PERSONAL DATA <br /><br />
          We will retain your Personal Data for as long as the application is
          installed on your mobile device or as needed to provide you service.
          If you remove the application, we will remove your Personal Data and
          use copies of your Personal Data as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements. If you wish
          to cancel your account or request that we no longer use your
          information to provide you services, contact us at
          <a href="mailto:support@myketo.care">support@myketo.care</a>.
          <br /><br />
          We use servers located in the European Union (Germany) to transfer,
          process, and store your Personal Data <br /><br />
          7. CHILDREN'S PRIVACY <br /><br />
          <strong>General age limitation.</strong> We are committed to
          protecting the privacy of children. The App is not intended for
          children and we do not intentionally collect information about
          children under 16 years old. The App does not collect Personal Data
          from any person the Company actually knows is under the age of 16. If
          you are aware of anyone under 16 using the App, please contact us at
          <a href="mailto:support@myketo.care">support@myketo.care</a> and we
          will take required steps to delete such information and (or) delete
          their account. <br /><br />
          8. DATA PROTECTION OFFICER <br /><br />
          To communicate with our Data Protection Officer, please email at
          <a href="mailto:support@myketo.care">support@myketo.care</a>.
          <br /><br />
          9. CONTACTING US <br /><br />
          <strong>General contact details.</strong> If you have any questions or
          concerns about your privacy, any provisions of this Privacy Policy or
          any of your rights, you may contact us at:
          <a href="mailto:support@myketo.care">support@myketo.care</a><br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  max-height: 100vh;
  margin: 0;
}
.box {
  &__text {
    max-height: calc(100vh - 5rem);
    overflow: auto;
  }
}

ol,
ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
ul {
  list-style-type: disc;
}
</style>
