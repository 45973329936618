<script>
import gsap from 'gsap'
import { mapActions, mapGetters, mapState } from 'vuex'
import { KG_TO_LBS } from '@/enums'
export default {
  name: 'IntroBlock',
  data() {
    return {
      timeline: null,
      text: [
        'Based on your answers,',
        'we’ve created a personalized plan,',
        'designed to get you to {Xkg/lbs}.',
        'Let’s get started!'
      ]
    }
  },
  computed: {
    ...mapState('onboarding', ['start_intro']),
    ...mapState('user', ['onboarding']),
    ...mapGetters('user', ['is_metric']),
    weightUnit() {
      return this.is_metric ? 'kg' : 'lbs'
    },
    startWeight() {
      const kg = this.onboarding?.form?.weight || 100
      return this.is_metric ? Math.round(kg) : Math.round(kg / KG_TO_LBS)
    },
    goalWeight() {
      const kg = this.onboarding?.form?.weight_goal || 80
      return this.is_metric ? Math.round(kg) : Math.round(kg / KG_TO_LBS)
    },
    computed_text() {
      return this.text.map(text =>
        text
          .replace('{Xkg/lbs}', `${this.goalWeight}${this.weightUnit}`)
          .split(' ')
      )
    }
  },
  watch: {
    start_intro(start_intro) {
      if (start_intro) this.play()
    }
  },
  beforeDestroy() {
    this.timeline?.kill()
    this.timeline = null
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions('onboarding', ['closeIntro']),

    init() {
      const timeline = gsap.timeline({
        autoRemoveChildren: true,
        paused: true,
        onComplete: () => {
          this.closeIntro()
        }
      })

      timeline.to(this.$refs.bg, {
        x: '-50%',
        y: '-50%',
        duration: 1
      })

      timeline.to(this.$refs.logo, {
        opacity: 1,
        scale: 1,
        duration: 1,
        ease: 'back.out(1.7)'
      })
      timeline.to(
        this.$refs.logo,
        {
          y: 0,
          scale: 0.5,
          duration: 1
        },
        'label-1'
      )
      timeline.to(
        this.$refs.welcome,
        {
          y: '-50%',
          opacity: 1,
          duration: 1
        },
        'label-1'
      )
      timeline.to(this.$refs.welcome, {
        opacity: 0,
        duration: 0.7,
        delay: 1
      })

      this.computed_text.forEach((text, idx) => {
        let delay = 0
        text.forEach((word, index) => {
          timeline.to(
            this.$refs[`text-${idx}-word-${index}`],
            {
              // y: '0%',
              opacity: 1,
              duration: 0.3,
              delay
            },
            `text-${idx}-word`
          )
          delay += 0.07
        })
        timeline.to(this.$refs[`text-${idx}`], {
          opacity: 0,
          delay: 1
        })
      })

      this.timeline = timeline
    },
    play() {
      this.timeline.play()
    }
  }
}
</script>

<template>
  <div class="intro has-text-white">
    <div ref="bg" class="intro__bg"></div>
    <div class="intro__inner">
      <img
        ref="logo"
        src="~/assets/images/app_logo.svg"
        class="intro__logo"
        alt="Logo"
      />
      <div ref="welcome" class="welcome has-text-centered">
        <div class="welcome__title is-size-4">Welcome to</div>
        <div class="welcome__subtitle is-size-3 has-text-weight-bold">
          Keto Diet App
        </div>
      </div>
      <div class="intro__text">
        <div
          v-for="(item, idx) in computed_text"
          :key="idx"
          :ref="`text-${idx}`"
          class="is-size-3 has-text-weight-bold intro__text_sentence px-5"
        >
          <span
            v-for="(word, index) in item"
            :key="index"
            :ref="`text-${idx}-word-${index}`"
            class="intro__text_word"
            >{{ word }}&nbsp;</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.intro {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1000;

  &__bg {
    background: linear-gradient(
        138.63deg,
        #fa5779 -0.49%,
        rgba(250, 87, 121, 0.1) 29.53%,
        rgba(250, 87, 121, 0) 38.45%
      ),
      #7c33f1;
    content: '';
    width: 250vw;
    height: 120vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-150%, -150%);
    border-radius: 50%;
  }

  &__inner {
    position: absolute;
    left: 0;
    top: 0.7rem;
    right: 0;
    bottom: 0.7rem;
  }

  //width="162" height="78"
  &__logo {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transform: translate(calc(50vw - 81px), calc(50vh - 39px)) scale(0.2);
    z-index: 1;
  }

  &__text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    min-height: 14rem;
    transform: translateY(-50%);

    &_sentence {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }

    &_word {
      opacity: 0;
      //transform: translateY(50%);
      display: inline-block;
    }
  }
}
.welcome {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  opacity: 0;
  transform: translateY(50%);
}
</style>
