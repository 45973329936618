import { EXPERIMENTS } from '@/enums'
import { screens } from '@/onboarding/screens'

// initial state
export const state = () => {
  return {
    version: null,
    screens,
    config: null,
    start_intro: false
  }
}

// getters
export const getters = {
  start_intro({ start_intro }) {
    return start_intro
  },
  config({ config }) {
    return config
  },
  screensCount({ config }) {
    return config?.screens.length || 100
  },
  finishScreen({ config }) {
    return `/${config?.finish_screen?.id || 'register'}`
  },
  finishScreenData({ config }) {
    return config?.finish_screen
  },
  offerScreen({ config }) {
    return `/offer/${config?.offer || 'onboarding'}`
  },
  onboardingScreens({ config }) {
    return config?.screens || []
  },
  version({ version }) {
    return version
  },
  isMealPlan({ version }) {
    return version === EXPERIMENTS.ONBOARDING.MEAL_PLAN
  },
  screens({ screens }, { finishScreen, onboardingScreens }) {
    if (!onboardingScreens.length) {
      return screens
    }

    const onboardingScreensMap = onboardingScreens.reduce(
      (acc, { id, config }, idx) => {
        const nextId = idx + 1
        const screenId = onboardingScreens[nextId]?.id
        acc[id] = {
          idx,
          next: screenId ? `/s/${screenId}` : finishScreen,
          ...(config || {})
        }
        return acc
      },
      {}
    )

    return Object.entries(screens).reduce((acc, [id, cfg]) => {
      acc[id] = onboardingScreensMap[id]
        ? { ...cfg, ...onboardingScreensMap[id] }
        : cfg
      return acc
    }, {})
  },
  nextUrl(state, { finishScreen, onboardingScreens: flow }, { route }) {
    const { name } = route

    const deviceId = process.client
      ? window?.amplitude?.getInstance()?.options.deviceId
      : false

    if (!flow.length) {
      return null
    }

    if (!name.startsWith('s-')) {
      return `/s/${flow[0].id}${deviceId ? '?deviceId=' + deviceId : ''}`
    }

    const screenId = name.slice(2)
    const nextIdx = flow.findIndex(({ id }) => id === screenId) + 1

    if (nextIdx <= 0) {
      return null
    }

    if (nextIdx >= flow.length) {
      return finishScreen
    }

    return `/s/${flow[nextIdx].id}${deviceId ? '?deviceId=' + deviceId : ''}`
  }
}

export const actions = {
  async setVersion({ state, commit }, version) {
    commit('setVersion', version)

    switch (version) {
      case EXPERIMENTS.ONBOARDING.V1:
        return commit('setConfig', await import('@/onboarding/onboarding_v1'))
      default:
      case EXPERIMENTS.ONBOARDING.V1_1:
        return commit('setConfig', await import('@/onboarding/onboarding_v1.1'))
      case EXPERIMENTS.ONBOARDING.V1_2:
        return commit('setConfig', await import('@/onboarding/onboarding_v1.2'))
      case EXPERIMENTS.ONBOARDING.V1_3:
        return commit('setConfig', await import('@/onboarding/onboarding_v1.3'))
      case EXPERIMENTS.ONBOARDING.V1_4:
        return commit('setConfig', await import('@/onboarding/onboarding_v1.4'))
      case EXPERIMENTS.ONBOARDING.V1_4_TRIAL_V1_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v1.4_trial_v1.1')
        )
      case EXPERIMENTS.ONBOARDING.V1_5:
        return commit('setConfig', await import('@/onboarding/onboarding_v1.5'))
      case EXPERIMENTS.ONBOARDING.V1_6:
        return commit('setConfig', await import('@/onboarding/onboarding_v1.6'))
      case EXPERIMENTS.ONBOARDING.MEAL_PLAN: {
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_meal_plan')
        )
      }
      case EXPERIMENTS.ONBOARDING.V1_7_TRIAL_V1_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v1.7_trial_v1.1')
        )
      case EXPERIMENTS.ONBOARDING.V1_8_TRIAL_V1_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v1.8_trial_v1.1')
        )
      case EXPERIMENTS.ONBOARDING.V1_9_TRIAL_V1_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v1.9_trial_v1.1')
        )
      case EXPERIMENTS.ONBOARDING.V2_0_TRIAL_V1_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.0_trial_v1.1')
        )
      case EXPERIMENTS.ONBOARDING.V2_0_TRIAL_V1_2:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.0_trial_v1.2')
        )
      case EXPERIMENTS.ONBOARDING.V2_0_TRIAL_V1_3:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.0_trial_v1.3')
        )
      case EXPERIMENTS.ONBOARDING.V2_0_TRIAL_V1_4:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.0_trial_v1.4')
        )
      case EXPERIMENTS.ONBOARDING.V2_0_INTRODUCTORY_V0_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.0_introductory_v0.1')
        )
      case EXPERIMENTS.ONBOARDING.V2_1_INTRODUCTORY_V0_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.1_introductory_v0.1')
        )
      case EXPERIMENTS.ONBOARDING.V2_2_INTRODUCTORY_V0_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.2_introductory_v0.1')
        )
      case EXPERIMENTS.ONBOARDING.V2_3_INTRODUCTORY_V0_1:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.3_introductory_v0.1')
        )
      case EXPERIMENTS.ONBOARDING.V2_0_INTRODUCTORY_V0_2:
        return commit(
          'setConfig',
          await import('@/onboarding/onboarding_v2.0_introductory_v0.2')
        )
    }
  },

  async startIntro({ commit }) {
    commit('startIntro', true)
    return new Promise(resolve => setTimeout(resolve, 1500))
  },
  async closeIntro({ commit }) {
    return new commit('startIntro', false)
  }
}

export const mutations = {
  setVersion(state, version) {
    state.version = version
  },
  setConfig(state, { onboarding }) {
    state.config = onboarding
  },
  startIntro(state, is_start) {
    state.start_intro = is_start
  }
}
