import { PAYAMENT_GATES, EXPERIMENTS } from '@/enums'

export const strict = true

const getFBMetadata = (current_params, req) => {
  console.log('getFBMetadata', { current_params })
  if (!current_params || !Object.keys(current_params).length) return 0

  const event = {}
  if (req && req.headers) {
    const ip =
      req.headers['x-appengine-user-ip'] ||
      req.headers['x-forwarded-for'] ||
      req.connection.remoteAddress ||
      ''
    const user_agent = req.headers['user-agent']
    event.ip = ip
    event.user_agent = user_agent
    // event.source_url = 'https://' + req.headers.host + req.originalUrl
    event.source_url = 'https://quiz.keto-diet-app.com' + req.originalUrl
  }
  const {
    utm_content,
    pid,
    af_prt,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_term,
    ppid,
    fbclid
  } = current_params

  if (utm_content) {
    event.af_adset = utm_content
    event.utm_content = utm_content
  }

  if (pid) {
    event.pid = pid
  } else {
    event.pid = 'Web2Web_Funnel'
  }

  if (af_prt) {
    event.af_prt = af_prt
  }

  if (utm_source) {
    event.media_source = utm_source
  }

  if (utm_campaign) {
    event.c = utm_campaign
    event.af_campaign = utm_campaign
  }

  if (utm_term) {
    event.af_ad = utm_term
  }

  if (utm_medium) {
    event.utm_medium = utm_medium
  }

  if (ppid) {
    event.af_r = `https://apps.apple.com/US/app/id1406879426?ppid=${ppid}`
  }

  // Cookie starts
  const getCookie = name => {
    let matches =
      req.headers && req.headers.cookie
        ? req.headers.cookie.match(
            new RegExp(
              '(?:^|; )' +
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                '=([^;]*)'
            )
          )
        : false
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
  let fbp = getCookie('_fbp')
  let fbc = getCookie('_fbc')
  if (fbclid) {
    if (!fbp)
      fbp =
        'fb.1.' +
        Math.round(Date.now()) +
        '.' +
        Math.round(Math.random() * 100000 * 100000)
    if (!fbc) fbc = 'fb.1.' + Math.round(Date.now()) + '.' + fbclid
  }

  if (fbc) {
    event.fbc = fbc
  }

  if (fbp) {
    event.fbp = fbp
  }

  console.log('getFBMetadata', { event })

  return event
}

export const actions = {
  async nuxtServerInit(
    { commit, dispatch, state, getters },
    { $config, route, req }
  ) {
    const isMealplanUrl =
      route.name === 'meal_plan' || route.name === 'meal_plan_pp'

    if (isMealplanUrl) {
      this.$cookies.set('onboradingVersion', EXPERIMENTS.ONBOARDING.MEAL_PLAN, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7
      })
    } else if (route.name === 'index') {
      this.$cookies.remove('onboradingVersion')
    }

    const onboradingVersion = this.$cookies.get('onboradingVersion')

    const version =
      route.query?.mealplanversion ||
      (route.name === 'index' ? null : onboradingVersion) ||
      $config.default_onboarding_version

    const { utm_source, utm_medium, designs } = route.query ?? {}

    if (utm_source) {
      commit('user/setMetaDataSource', utm_source)
    }

    if (utm_medium) {
      commit('user/setMetaDataMedium', utm_medium)
    }

    const metaData = await getFBMetadata(route.query, req)
    if (metaData) {
      commit('user/setMetaData', metaData)
    }

    if (designs) {
      if (typeof designs === 'string') {
        commit('experiment/setDesigns', [
          ...state.experiment.designs,
          ...JSON.parse(designs)
        ])
      }
    }

    await Promise.all([
      dispatch(
        'browser/detectBrowser',
        process.browser ? window.navigator.userAgent : req.headers['user-agent']
      ),
      dispatch('plan/defaultPlanIds', {
        planIds: [...$config.default_plan_ids]
      }),
      dispatch('onboarding/setVersion', version)
    ])

    console.log('onboarding version', version)

    if (getters['browser/isMobileSafari'])
      commit('plan/setDefaultPaymentGates', [
        PAYAMENT_GATES.CARD,
        PAYAMENT_GATES.PAYPAL
      ])

    if (route.query.plan) {
      // preload default plan
      if (Array.isArray(route.query.plan))
        await dispatch('plan/defaultPlanIds', {
          planIds: [...route.query.plan]
        })
      else if (typeof route.query.plan === 'string')
        await dispatch('plan/defaultPlanIds', {
          planIds: [route.query.plan]
        })
    }
  }
}
