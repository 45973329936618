import { UNIT_SYSTEM } from '@/enums'
import { addDays, format } from 'date-fns'

function safeLocalStorageJSON(key) {
  try {
    if (process.browser) {
      return JSON.parse(localStorage.getItem(key)) || null
    }
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}

function defaultUnitSystem() {
  return UNIT_SYSTEM.IMPERIAL
}

function initialFormState() {
  return {
    weightLossSpeed: 0.85
  }
}

function initialOnboardingState() {
  return {
    payment_provider: 'recurly',
    acquisition_type: 'web'
  }
}

// initial state
export const state = () => {
  return {
    user: safeLocalStorageJSON('user'),
    credentials: {
      email: null,
      password: null
    },
    unit_system: defaultUnitSystem(),
    onboarding: {
      email: null,
      form: initialFormState(),
      ...initialOnboardingState(),
      screens: {}
    },
    personal_plan: {},
    metaData: {
      source: null,
      medium: null
    }
  }
}

// getters
export const getters = {
  is_metric(state) {
    return state.unit_system === UNIT_SYSTEM.METRIC
  },
  personal_plan(state) {
    return state.personal_plan
  },
  onboarding(state) {
    return state.onboarding || {}
  },
  form(state) {
    return state.onboarding?.form || {}
  },
  metaData(state) {
    return state.metaData
  },
  metaDataSource(state) {
    return state.metaData.source
  },
  metaDataMedium(state) {
    return state.metaData.medium
  }
}

// actions
export const actions = {
  calcPersonalPlan({ state, commit }, { form }) {
    let speedLostWeight = form.weightLossSpeed || 0
    let startWeight = form.weight || 100

    let convertToImperial = !state.is_metric
    let lbsInKg = 0.453592

    // скорость fast - твой вес будет -12 lbs (), moderate — -6lbs, relaxed — -2lbs от текущего веса

    let goalLostWeigth = 0
    switch (speedLostWeight) {
      case 0.9:
        goalLostWeigth = convertToImperial ? 2 : 2 * lbsInKg
        break
      case 0.85:
        goalLostWeigth = convertToImperial ? 6 : 6 * lbsInKg
        break
      case 0.8:
        goalLostWeigth = convertToImperial ? 12 : 12 * lbsInKg
        break
      default:
        goalLostWeigth = convertToImperial ? 2 : 2 * lbsInKg
        break
    }

    let v = startWeight / lbsInKg
    let startValue = convertToImperial ? v : startWeight
    let goalWeight = convertToImperial
      ? startWeight / lbsInKg - goalLostWeigth
      : startWeight - goalLostWeigth
    let middleWeight = startValue - (startValue - goalWeight) * 0.4

    const endWeight = () => {
      const diff = Math.min(startValue - goalWeight, this.is_metric ? 6 : 12)
      return startValue - diff
    }

    commit('setPersonalPlan', {
      unit: state.is_metric ? 'Kg' : 'Lb',
      middle_date: format(addDays(new Date(), 7), 'MMM d'),
      end_date: format(addDays(new Date(), 14), 'MMM d'),
      startValue: Math.round(startValue),
      middleWeight: Math.round(middleWeight),
      goalWeight: Math.round(endWeight(goalWeight))
    })
  },
  async onAuthStateChangedAction({ state, commit }, { authUser }) {
    if (authUser && authUser.uid) {
      const doc = await this.$fire.firestore.doc(`/users/${authUser.uid}`).get()
      if (doc.exists) {
        const user = doc.data()
        commit('updateOnboarding', { ...user })
        const unit_system = !!user?.settings?.is_metric
          ? UNIT_SYSTEM.METRIC
          : UNIT_SYSTEM.IMPERIAL

        commit('setUnitSystem', unit_system)
      }
    }
  },
  onSetAppUserAction({ state, commit }, data) {
    const user = {
      ...data,
      ...(data.email ? { email: data.email.replace(' ', '+') } : {})
    }
    commit('setAppUserById', user)
    commit('setUserEmail', user.email)
  }
}

// mutations
export const mutations = {
  updateCredentials(state, { email, password }) {
    state.credentials = { email, password }
    if (email) {
      state.onboarding.email = email
    }
  },
  onAuthStateChanged(state, { authUser }) {
    state.user = authUser && authUser.toJSON()
    localStorage.setItem('user', authUser && JSON.stringify(authUser.toJSON()))
  },
  setAppUserById(state, data) {
    state.user = data
  },
  setUnitSystem(state, unit_system) {
    state.unit_system = unit_system
  },
  updateOnboarding(state, { form = {}, screens = {} }) {
    state.onboarding = {
      ...state.onboarding,
      form: { ...state.onboarding.form, ...form },
      screens: { ...state.onboarding.screens, ...screens }
    }
  },
  resetOnboardingForm(state) {
    state.onboarding = {
      form: initialFormState(),
      ...initialOnboardingState(),
      screens: { ...state.onboarding.screens }
    }
  },
  setPersonalPlan(state, personal_plan) {
    state.personal_plan = {
      ...personal_plan
    }
  },
  setUserPlanIds(state, { planIds }) {
    state.onboarding.form.planIds = [...planIds]
  },
  setUserEmail(state, email) {
    state.onboarding.email = email
  },
  setMetaData(state, metaData) {
    state.metaData = {
      ...state.metaData,
      ...metaData
    }
  },
  setMetaDataMedium(state, medium) {
    state.metaData.medium = medium
  },
  setMetaDataSource(state, source) {
    state.metaData.source = source
  }
}
